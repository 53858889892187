import { ActivationInterest } from '@/types';
import { ApiResponse } from '@/interfaces';
import { AxiosRequestConfig } from 'axios';
import { fetchData } from '@/api/utils';

export const searchStars = async (
  country: number,
  interests: number[],
  searchTerm: string
): Promise<ApiResponse> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/search/stars`);
  const params = new URLSearchParams();
  params.append('country', country.toString());
  params.append('interests', interests.join(','));
  params.append('search_term', searchTerm);
  url.search = params.toString();

  const config: AxiosRequestConfig = {
    method: 'GET',
    url: url.toString(),
  };

  return fetchData(config);
};

export const searchMetaInterests = async (
  searchTerm: string
): Promise<ApiResponse<Array<ActivationInterest>>> => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/search/meta`);
  const params = new URLSearchParams();
  params.append('search_term', searchTerm);
  url.search = params.toString();

  const config: AxiosRequestConfig = {
    method: 'GET',
    url: url.toString(),
  };

  return fetchData(config);
};
