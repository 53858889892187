import React, { FC, useState, useEffect } from "react";
import { sortBy } from "lodash";

import { Stack, Typography, SvgIcon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { InsightCard, Tooltip } from "@/components";
import { ITagScore } from "@/interfaces";
import { interestIcon } from "@/constants/icon-map";
import { useTheme } from "@mui/material/styles";

interface InsightCardInterestGroupProps {
  title: string;
  interests: ITagScore[];
}

function groupBy(list: ITagScore[], keyGetter: (x: ITagScore) => string) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const InsightCardInterestGroup: FC<InsightCardInterestGroupProps> = ({
  title,
  interests,
}) => {
  const theme = useTheme();
  const color = theme.palette.augmentColor({
    color: { main: theme.palette.background.paper },
  });

  const groupMap = groupBy(
    interests.slice(0, 30).filter((x) => x.group !== x.name),
    (interest: ITagScore) => interest.group
  );
  const groupScores = Array.from(groupMap.values()).map((interests, idx) => ({
    name: Array.from(groupMap.keys())[idx],
    index:
      interests.reduce(
        (total: number, next: ITagScore) => total + next.index,
        0
      ) / interests.length,
    size: interests.reduce((total: number) => total + 1, 0),
  }));
  const groups = sortBy(
    groupScores.filter((x) => x.size >= 2),
    "index"
  )
    .reverse()
    .slice(0, 3);

  const [selectedGroup, setSelectedGroup] = useState<string>(groups[0].name);

  useEffect(() => {
    setSelectedGroup(groups[0].name);
  }, [interests]);

  const subInterests = sortBy(
    interests.filter(
      (interest) =>
        interest.group === selectedGroup && interest.name !== selectedGroup
    ),
    "index"
  )
    .reverse()
    .slice(0, 3);

  const handleGroupSelect = (interest: string) => {
    setSelectedGroup(interest);
  };

  const calculateInterestRadius = (
    groupIdx: number,
    interestIdx: number,
    lastIdx: number
  ) => {
    if (interestIdx === 0) {
      return "6px 0px 0px 6px";
    } else if (interestIdx === lastIdx - 1) {
      return "0px 6px 6px 0px";
    } else {
      return "0px 0px 0px 0px";
    }
  };

  const calculateInterestJustify = (groupIdx: number, numInterests: number) => {
    if (numInterests < 3) {
      if (groupIdx === 0) {
        return "flex-start";
      } else if (groupIdx === 2) {
        return "flex-end";
      } else {
        return "center";
      }
    } else {
      return "center";
    }
  };

  return (
    <InsightCard title={title}>
      <Grid container spacing={2} mx={1} justifyContent="center">
        <Grid xs={12} container mb={1}>
          {groups.map((group) => {
            return (
              <Grid xs={4} key={group.name}>
                <Stack
                  spacing={1}
                  pt={1}
                  alignItems="center"
                  onClick={() => handleGroupSelect(group.name)}
                  sx={{
                    "&:hover": {
                      backgroundColor: color.light,
                      borderRadius: "6px",
                    },
                    backgroundColor:
                      group.name === selectedGroup ? color.light : "",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                >
                  <SvgIcon
                    component={interestIcon(group.name).ReactComponent}
                    inheritViewBox
                    sx={{ fontSize: "30px" }}
                  />
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {group.name.split(" / ")[0]}
                  </Typography>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          xs={12}
          container
          justifyContent={calculateInterestJustify(
            groups.map((x) => x.name).indexOf(selectedGroup),
            subInterests.length
          )}
          sx={{ border: `1px solid ${color.light}`, borderRadius: "6px" }}
        >
          {subInterests.map((interest, idx) => {
            return (
              <Grid
                xs={4}
                key={interest.name}
                sx={{
                  // backgroundColor: color.light,
                  borderRadius: calculateInterestRadius(
                    groups.map((x) => x.name).indexOf(selectedGroup),
                    idx,
                    subInterests.length
                  ),
                }}
              >
                <Tooltip placement="bottom" title={interest.name}>
                  <Stack spacing={1} alignItems="center">
                    <SvgIcon
                      component={interestIcon(interest.name).ReactComponent}
                      inheritViewBox
                      sx={{ fontSize: "30px" }}
                    />
                    <Typography
                      variant="caption"
                      align="center"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {interest.name.split(" / ")[0]}
                    </Typography>
                  </Stack>
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </InsightCard>
  );
};
