export enum InsightMode {
  STALE = 'STALE',
  READY = 'READY',
  NEEDS_TO_RUN = 'NEEDS_TO_RUN',
}

export enum QueryMode {
  INTEREST = 'interest',
  STAR = 'star',
}

export enum QueryOperator {
  ANY = 'ANY',
  ALL = 'ALL',
  MORE_THAN = 'MORE THAN',
  LESS_THAN = 'LESS THAN',
}

export enum ProgressStep {
  Idle,
  BuildingAudience,
  PreparingSegment,
  BuildingSegment,
  GettingInsights,
  Error,
  Completed,
  PreparingSegmentError,
  BuildingSegmentError,
  BuildingAudienceError,
  GettingInsightsError,
}

export enum ActivationGender {
  male = 1,
  female = 2,
}
