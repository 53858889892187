import { Box, Tooltip } from '@mui/material';

import { InsightMode } from '@/enums';
import React from 'react';
import { determineMode } from '@/utils';

interface InsightModeIndicatorProps {
  id?: number;
  date?: string;
}

const INSIGHT_MODE_COLORS = {
  [InsightMode.STALE]: '#FDCD82',
  [InsightMode.READY]: '#9fd6b2',
  [InsightMode.NEEDS_TO_RUN]: '#F3546D',
};

const modeDescriptions = {
  [InsightMode.STALE]: 'Insight needs refreshing',
  [InsightMode.READY]: 'Insight ready',
  [InsightMode.NEEDS_TO_RUN]: 'Insight needs to be run',
};

export const InsightModeIndicator: React.FC<InsightModeIndicatorProps> = ({
  id,
  date,
}) => {
  const mode = determineMode(id, date);

  return (
    <Tooltip title={modeDescriptions[mode]}>
      <Box
        m={1}
        className="insight-mode-indicator"
        style={{ backgroundColor: INSIGHT_MODE_COLORS[mode] }}
      />
    </Tooltip>
  );
};

interface BasicIndicatorProps {
  mode: InsightMode;
  margin: number;
}

export const BasicIndicator: React.FC<BasicIndicatorProps> = ({
  mode,
  margin,
}) => {
  return (
    <Box
      mr={margin}
      className="insight-mode-indicator"
      style={{ backgroundColor: INSIGHT_MODE_COLORS[mode] }}
    />
  );
};
