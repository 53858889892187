import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Stack
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Edit, Female, Male } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import { ActivationScore } from '@/types';
import { titleCase } from '@/helpers';

const genders = ['male', 'female', 'both'];

interface ActivateDemographicSectionProps {
  topGender: string;
  topAge: string;
  onSave: (gender: string, age: { min: string; max: string }) => void;
}

const ActivateDemographicSection: React.FC<ActivateDemographicSectionProps> = ({
  topGender,
  topAge,
  onSave,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [age, setAge] = useState<{
    min: string;
    max: string;
  }>({
    min: '',
    max: '',
  });
  const [gender, setGender] = useState<string>('');

  useEffect(() => {
    setAge({
      min: topAge.split('-')[0].trim(),
      max: topAge.split('-')[1].trim(),
    });
    setGender(topGender);
  }, [topAge, topGender]);

  const handleAgeChange = (event: SelectChangeEvent<string>) => {
    setAge(
      (prev) =>
        ({
          ...prev,
          [event.target.name]: event.target.value.toString(),
        } as { min: string; max: string })
    );
  };

  const handleGenderChange = (event: SelectChangeEvent<string>) => {
    setGender(event.target.value);
  };

  const handleSave = () => {
    setEditMode(false);
    onSave(gender, age);
  };

  const handleCancel = () => {
    setAge({
      min: topAge.split('-')[0].trim(),
      max: topAge.split('-')[1].trim(),
    });
    setGender(topGender);
    setEditMode(false);
  };

  return (
    <Grid xs={12} container display="flex" flexDirection="row" columnSpacing={4} rowSpacing={1} p={2}>
      <Grid xs={2}>
        <Stack spacing={1}>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="body1" fontWeight="bold">
              Demographics
            </Typography>
            {!editMode &&
              <Button
                variant="text"
                size="small"
                startIcon={<Edit />}
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            }
          </Box>
          {editMode &&
            <Box display="flex" gap={1}>
              <Button variant="contained" size="small" onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" size="small" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          }
        </Stack>
      </Grid>

      {topGender && (
        <>
          {editMode ? (
            <Grid>
              <Stack spacing={1}>
                <Typography variant="body1" fontWeight="bold">
                  Gender
                </Typography>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ minWidth: 120 }}
                >
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    value={gender}
                    onChange={handleGenderChange}
                    MenuProps={{ sx: { maxHeight: 300 } }}
                  >
                    {genders.map((item) => (
                      <MenuItem key={item} value={item}>
                        {titleCase(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          ) : (
            <Grid>
              <Stack spacing={1}>
                <Typography variant="body1" fontWeight="bold">
                  Gender
                </Typography>
                <Box display="flex">
                  {topGender === 'male' && <Male />}
                  {topGender === 'female' && <Female />}
                  {topGender === 'both' && (
                    <Box>
                      <Male />
                      <Female />
                    </Box>
                  )}
                  <Typography variant="body1">
                    {titleCase(topGender || '')}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          )}
        </>
      )}

      {topAge && (
        <>
          {editMode ? (
            <Grid>
              <Stack spacing={1}>
                <Typography variant="body1" fontWeight="bold">
                  Age Range
                </Typography>
                <Stack direction="row" spacing={1}>
                  <FormControl
                    variant="filled"
                    size="small"
                    sx={{ minWidth: 120 }}
                  >
                    <InputLabel>Minimum Age</InputLabel>
                    <Select
                      name="min"
                      value={age?.min}
                      onChange={handleAgeChange}
                      MenuProps={{ sx: { maxHeight: 300 } }}
                    >
                      {Array.from({ length: 50 }, (_, i) => i + 16).map(
                        (item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="filled"
                    size="small"
                    sx={{ minWidth: 120 }}
                  >
                    <InputLabel>Maximum Age</InputLabel>
                    <Select
                      name="max"
                      value={age?.max}
                      onChange={handleAgeChange}
                      MenuProps={{ sx: { maxHeight: 300 } }}
                    >
                      {Array.from({ length: 50 }, (_, i) => i + 16).map(
                        (item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <Grid>
              <Stack spacing={1}>
                <Typography variant="body1" fontWeight="bold">
                  Age Range
                </Typography>
                <Typography variant="body1">{topAge}</Typography>
              </Stack>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default ActivateDemographicSection;
