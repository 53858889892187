import { useEffect, useState } from 'react';

import { IActivationCountry } from '@/interfaces';
import { getMetaCountries } from '@/api/list';

export const useMetaCountryList = () => {
  const [metaCountryList, setMetaCountryList] = useState<IActivationCountry[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    getMetaCountries()
      .then((response) => {
        setMetaCountryList(response.data);
        setError(null);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { metaCountryList, loading, error };
};
