import React, {
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { Action } from './types';
import { IAudience } from '@/interfaces';
import { audienceReducer } from './audience-reducer';
import { getAudiences } from '@/api/audience';
import { useAuth0 } from '@auth0/auth0-react';

interface AudienceState {
  audiences: IAudience[];
  isLoading: boolean;
  error: string | null;
}

export const AudienceContext = React.createContext<{
  state: AudienceState;
  dispatch: React.Dispatch<Action>;
}>({
  state: {} as AudienceState,
  dispatch: () => null,
});

interface AudienceProviderProps {
  children: ReactNode;
  initialState: IAudience[];
}

export const AudienceProvider: React.FC<AudienceProviderProps> = ({
  children,
  initialState,
}) => {
  const [state, dispatch] = useReducer(audienceReducer, initialState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAudiences(accessToken);
      if (data) {
        dispatch({ type: 'SET_AUDIENCE', payload: data });
        setIsLoading(false);
      }

      if (error) {
        setIsLoading(false);
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <AudienceContext.Provider
      value={{ state: { audiences: state, isLoading, error }, dispatch }}
    >
      {children}
    </AudienceContext.Provider>
  );
};

export const useAudience = () => {
  const context = useContext(AudienceContext);
  if (context === undefined) {
    throw new Error('useAudience must be used within an AudienceProvider');
  }
  return context;
};
