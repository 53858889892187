import { useEffect, useState } from 'react';

import { ICountry } from '@/interfaces';
import { getCountries } from '@/api/list';

export const useCountryList = () => {
  const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    getCountries()
      .then((response) => {
        setCountryList(response.data);
        setError(null);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { countryList, loading, error };
};
