import { ActivationInterest, SegmentInterest } from '@/types';
import { AddCircleRounded, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { AddInterestsDialog } from '../add-interests-dialog';
import { interestTypeColors } from '@/constants';

interface InterestDrawerProps {
  openDrawer: boolean;
  onOpenDrawer: (open: boolean) => void;
  segmentNames: string[];
  selectedSegment: string;
  segmentInterests: SegmentInterest[];
  segmentInterestsGroups: string[];
  interests: ActivationInterest[];
  interestGroups: string[];
  interestTypes: string[];
  editedInterests: ActivationInterest[];
  onAddActivationInterest: (interest: ActivationInterest) => void;
  onSelectedSegment: (segment: string) => void;
  onAddInterest: (interest: ActivationInterest) => void;
  onRemoveInterest: (interest: string) => void;
  onSelectDeselectAll: (
    group: string,
    editedInterests: ActivationInterest[],
    segmentInterests: SegmentInterest[] | undefined,
    interests: ActivationInterest[] | undefined
  ) => void;
}

interface InterestChipProps {
  interest: ActivationInterest;
  isSelected: boolean;
  onAddInterest: (interest: ActivationInterest) => void;
  onRemoveInterest: (interest: string) => void;
}

const InterestChip: React.FC<InterestChipProps> = memo(
  ({ interest, isSelected, onAddInterest, onRemoveInterest }) => {
    const handleClick = useCallback(() => {
      if (isSelected) {
        onRemoveInterest(interest.name);
      } else {
        onAddInterest(interest);
      }
    }, [isSelected, interest, onAddInterest, onRemoveInterest]);

    const chipColor =
      interestTypeColors[interest.type as keyof typeof interestTypeColors];

    return (
      <Chip
        size="small"
        label={interest.name}
        color={isSelected ? 'primary' : 'default'}
        onClick={handleClick}
        disabled={isSelected}
        sx={{
          bgcolor: isSelected ? chipColor : 'default',
          borderColor: chipColor,
        }}
      />
    );
  }
);

interface InterestGroupProps {
  group: string;
  interests: ActivationInterest[];
  editedInterests: ActivationInterest[];
  segmentInterests?: SegmentInterest[];
  onAddInterest: (interest: ActivationInterest) => void;
  onRemoveInterest: (interest: string) => void;
  onSelectDeselectAll: (
    group: string,
    editedInterests: ActivationInterest[],
    segmentInterests: SegmentInterest[] | undefined,
    interests: ActivationInterest[] | undefined
  ) => void;
}

const InterestGroup: React.FC<InterestGroupProps> = memo(
  ({
    group,
    interests,
    editedInterests,
    segmentInterests,
    onAddInterest,
    onRemoveInterest,
    onSelectDeselectAll,
  }) => {
    // Filter interests for this group
    const groupInterests = useMemo(
      () => interests.filter((int) => int.group === group),
      [interests, group]
    );

    const handleSelectDeselectAll = useCallback(() => {
      onSelectDeselectAll(group, editedInterests, segmentInterests, interests);
    }, [
      group,
      editedInterests,
      segmentInterests,
      interests,
      onSelectDeselectAll,
    ]);

    if (!groupInterests.length) return null;

    // Check if all are selected
    const isAllSelected = groupInterests.every((interest) =>
      editedInterests.some((ei) => ei.name === interest.name)
    );

    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6" fontWeight="bold">
          {group}
        </Typography>
        <Grid container gap={1}>
          {groupInterests.map((interest) => {
            const isSelected = editedInterests.some(
              (ei) => ei.name === interest.name
            );
            return (
              <Grid item key={interest.name}>
                <InterestChip
                  interest={interest}
                  isSelected={isSelected}
                  onAddInterest={onAddInterest}
                  onRemoveInterest={onRemoveInterest}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid container>
          <Button variant="text" size="small" onClick={handleSelectDeselectAll}>
            {isAllSelected ? 'Deselect All' : 'Select All'}{' '}
            {groupInterests.length}
          </Button>
        </Grid>
      </Box>
    );
  }
);

const FilterSection: React.FC<{
  interestTypes: string[];
  selectedType: string;
  searchTerm: string;
  handleTypeSelect: (type: string) => void;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddOtherInterests: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = memo(
  ({
    interestTypes,
    selectedType,
    searchTerm,
    handleTypeSelect,
    handleSearchChange,
    handleAddOtherInterests,
  }) => {
    return (
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h6" fontWeight="bold">
          Filters
        </Typography>
        <Grid container spacing={1}>
          {interestTypes.map((type) => {
            const chipColor =
              interestTypeColors[type as keyof typeof interestTypeColors];
            const isSelectedType = selectedType === type;
            return (
              <Grid item key={type}>
                <Chip
                  variant="outlined"
                  size="small"
                  label={type}
                  onClick={() => handleTypeSelect(type)}
                  sx={{
                    borderColor: chipColor,
                    bgcolor: isSelectedType ? chipColor : 'default',
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid alignItems={'center'} container>
          <Grid xs={10}>
            <TextField
              label="Interest Search"
              variant="filled"
              fullWidth
              size="small"
              placeholder="Search for an interest"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={2} display="flex" justifyContent="center">
            <Tooltip title="Add Interest">
              <IconButton size="small" onClick={handleAddOtherInterests}>
                <AddCircleRounded />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

const InterestDrawer: React.FC<InterestDrawerProps> = ({
  openDrawer,
  onOpenDrawer,
  segmentNames,
  selectedSegment,
  segmentInterests,
  segmentInterestsGroups,
  interests,
  interestGroups,
  interestTypes,
  editedInterests,
  onAddActivationInterest,
  onSelectedSegment,
  onAddInterest,
  onRemoveInterest,
  onSelectDeselectAll,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [displayOtherInterests, setDisplayOtherInterests] = useState(false);

  const handleClose = () => onOpenDrawer(false);

  const handleAddOtherInterests = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setDisplayOtherInterests(true);
  };

  const handleSegmentChange = (event: SelectChangeEvent<string>) => {
    onSelectedSegment(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleTypeSelect = (type: string) => {
    setSelectedType((prev) => (prev === type ? '' : type));
  };

  // Segment-based data
  const filteredSegmentInterests = useMemo(() => {
    // Typically segmentInterests[0].interests if you only handle one segment array
    const all = segmentInterests[0]?.interests || [];
    const lowerSearch = searchTerm.toLowerCase();
    return all.filter((item) => {
      const matchesSearch =
        !searchTerm || item.name.toLowerCase().includes(lowerSearch);
      const matchesType = !selectedType || item.type === selectedType;
      return matchesSearch && matchesType;
    });
  }, [segmentInterests, searchTerm, selectedType]);

  // Non-segment data
  const filteredInterests = useMemo(() => {
    const lowerSearch = searchTerm.toLowerCase();
    return interests.filter((item) => {
      const matchesSearch =
        !searchTerm || item.name.toLowerCase().includes(lowerSearch);
      const matchesType = !selectedType || item.type === selectedType;
      return matchesSearch && matchesType;
    });
  }, [interests, searchTerm, selectedType]);

  const handleRemoveActivationInterest = useCallback(
    (interest) => {
      if (interests.length > 0) {
        interests.filter((i) => i.name !== interest);
      }
    },
    [interests]
  );

  return (
    <>
      <Drawer anchor="right" open={openDrawer} onClose={handleClose}>
        <Box
          sx={{
            width: 400,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Add Interest
          </Typography>
          <Typography variant="body2">
            Select the interests you would like to add to your target audience.
          </Typography>

          {!!segmentNames.length && (
            <>
              <FormControl variant="filled" fullWidth size="small">
                <InputLabel>Segments</InputLabel>
                <Select
                  variant="filled"
                  value={selectedSegment}
                  onChange={handleSegmentChange}
                >
                  {segmentNames.map((seg) => (
                    <MenuItem key={seg} value={seg}>
                      {seg}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="body2" color="textSecondary">
                Please select a segment above.
              </Typography>
            </>
          )}

          {/* ---------------- Render Groups: segment or non-segment ---------------- */}
          {segmentNames.length === 0 ? (
            <>
              <FilterSection
                {...{
                  interestTypes,
                  selectedType,
                  searchTerm,
                  handleTypeSelect,
                  handleSearchChange,
                  handleAddOtherInterests,
                }}
              />

              {filteredInterests.length > 0 ? (
                interestGroups.map((group) => (
                  <InterestGroup
                    key={group}
                    group={group}
                    interests={filteredInterests}
                    editedInterests={editedInterests}
                    onAddInterest={onAddInterest}
                    onRemoveInterest={onRemoveInterest}
                    onSelectDeselectAll={onSelectDeselectAll}
                  />
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No interest found
                </Typography>
              )}
            </>
          ) : (
            selectedSegment && (
              <>
                <FilterSection
                  {...{
                    interestTypes,
                    selectedType,
                    searchTerm,
                    handleTypeSelect,
                    handleSearchChange,
                    handleAddOtherInterests,
                  }}
                />
                {filteredSegmentInterests.length > 0 ? (
                  segmentInterestsGroups.map((group) => (
                    <InterestGroup
                      key={group}
                      group={group}
                      interests={filteredSegmentInterests}
                      editedInterests={editedInterests}
                      onAddInterest={onAddInterest}
                      onRemoveInterest={onRemoveInterest}
                      onSelectDeselectAll={onSelectDeselectAll}
                    />
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No interests found.
                  </Typography>
                )}
              </>
            )
          )}
        </Box>
      </Drawer>
      {displayOtherInterests && (
        <AddInterestsDialog
          open
          onClose={() => setDisplayOtherInterests(false)}
          existingInterests={filteredSegmentInterests || filteredInterests}
          onAddActivationInterest={onAddActivationInterest}
          onRemoveActivationInterest={handleRemoveActivationInterest}
        />
      )}
    </>
  );
};

export default memo(InterestDrawer);
