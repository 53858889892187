import { useCallback, useEffect, useState } from 'react';

import { AudienceCheckApiResponse } from '@/interfaces';
import { checkAudience } from '@/api/audience';
import { useAuth0 } from '@auth0/auth0-react';

export const useAudienceCheck = (id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const [audienceCheck, setAudienceCheck] =
    useState<AudienceCheckApiResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const response = await checkAudience(accessToken, id);
      setAudienceCheck(response.data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, [id, getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { audienceCheck, loading, error };
};
