import { AgeCheckbox, NewAudienceOnboardingSkeleton } from '@/components';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { ageOptions, defaultAudience, genderOptions } from '@/constants';

import { AudienceContext } from '@/context/audience-context';
import { IAudience } from '@/interfaces';
import { SelectChangeEvent } from '@mui/material/Select';
import { getAgeSummary } from '@/helpers';
import { useCountryList } from '@/hooks/useCountryList';
import { useHistory } from 'react-router-dom';

interface NewAudienceOnboardingDialogProps {
  open: boolean;
  onClose: () => void;
  onCreate: (audience: IAudience) => void;
}

export const NewAudienceOnboardingDialog: FC<
  NewAudienceOnboardingDialogProps
> = ({ open, onClose, onCreate }) => {
  const { state } = useContext(AudienceContext);
  const { audiences } = state;
  const [audience, setAudience] = useState<IAudience>(defaultAudience);
  const [audienceNameExists, setAudienceNameExists] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<{
    type: 'error' | 'success';
    message: string;
  } | null>(null);
  const { name, country, age, gender, project_name } = audience;
  const { countryList, loading } = useCountryList();
  const history = useHistory();

  const projects = Array.from(
    new Set(
      audiences
        .filter((a) => a.project_name !== null)
        .map((a) => a.project_name as string)
        .sort((a, b) => a.localeCompare(b))
    )
  );

  const onBackToAudiences = () => history.push('/');

  const handleAudienceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAudience({ ...audience, name: event.target.value });
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    setAudience({ ...audience, country: Number(event.target.value) });
  };

  const handleAgeSelect = (age: string[]) => {
    setAudience({ ...audience, age });
  };

  const handleGenderSelect = (gender: string) => {
    setAudience({ ...audience, gender: [gender] });
  };

  const handleCreate = () => {
    const audienceNameExistsForCountry = audiences.find(
      (a) =>
        a.name === audience?.name &&
        a.country === audience?.country &&
        a.id !== audience?.id
    );

    if (name === '' || name === undefined) {
      setSnackbarMessage({
        type: 'error',
        message: 'Audience name is required',
      });
      return;
    }

    if (audienceNameExistsForCountry) {
      setAudienceNameExists(true);
      setSnackbarMessage({
        type: 'error',
        message: 'Audience name already exists for this country',
      });
      return;
    } else {
      setAudienceNameExists(false);
      setSnackbarMessage(null);
      onCreate(audience);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onBackToAudiences}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 1)',
      }}
    >
      <Fade in={open}>
        <Box sx={{ width: 400 }}>
          {loading ? (
            <NewAudienceOnboardingSkeleton />
          ) : (
            <>
              <DialogTitle>New Audience</DialogTitle>
              <DialogContent>
                <Autocomplete
                  freeSolo
                  value={project_name || ''}
                  onChange={(_event, value) => {
                    setAudience({
                      ...audience,
                      project_name: value === '' ? null : value,
                    });
                  }}
                  inputValue={project_name || ''}
                  onInputChange={(_event, newInputValue) => {
                    setAudience({
                      ...audience,
                      project_name:
                        newInputValue.trim() === '' ? null : newInputValue,
                    });
                  }}
                  options={projects}
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Campaign Name"
                      size="small"
                      sx={{ mb: 2 }}
                    />
                  )}
                />
                <TextField
                  variant="filled"
                  size="small"
                  color="primary"
                  fullWidth
                  label="Audience Name"
                  placeholder="Enter audience name"
                  value={name}
                  required={true}
                  onChange={handleAudienceNameChange}
                  error={
                    snackbarMessage !== null &&
                    (audience?.name === '' || audienceNameExists)
                  }
                  helperText={
                    snackbarMessage !== null &&
                    (audience?.name === '' || audienceNameExists) &&
                    snackbarMessage?.message
                  }
                />
                <FormControl
                  variant="filled"
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                >
                  <InputLabel>Country</InputLabel>
                  <Select
                    variant="filled"
                    value={country.toString()}
                    onChange={handleCountryChange}
                  >
                    {countryList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="filled"
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                >
                  <InputLabel>Age</InputLabel>
                  <Select
                    multiple
                    variant="filled"
                    displayEmpty
                    value={age}
                    renderValue={(value: string[]) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {value.length > 0 && (
                          <Box display="flex" flexDirection="row">
                            {getAgeSummary(age)}
                          </Box>
                        )}
                      </Box>
                    )}
                  >
                    <FormGroup sx={{ p: 1 }}>
                      <AgeCheckbox
                        labels={ageOptions}
                        values={age}
                        onValueChange={handleAgeSelect}
                      />
                    </FormGroup>
                  </Select>
                </FormControl>
                <FormControl
                  variant="filled"
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                >
                  <InputLabel>Gender</InputLabel>
                  <Select
                    multiple
                    variant="filled"
                    displayEmpty
                    value={gender}
                    renderValue={(value: string[]) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {value.length > 0 && (
                          <Box display="flex" flexDirection="row">
                            {gender.toString()}
                          </Box>
                        )}
                      </Box>
                    )}
                  >
                    <FormGroup sx={{ p: 1 }}>
                      {genderOptions.map((label: string) => (
                        <FormControlLabel
                          key={label}
                          label={label}
                          control={
                            <Checkbox
                              checked={gender?.includes(label)}
                              onChange={() => handleGenderSelect(label)}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  variant={'outlined'}
                  onClick={onBackToAudiences}
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <Button variant={'contained'} onClick={handleCreate}>
                  Create
                </Button>
              </DialogActions>
            </>
          )}
        </Box>
      </Fade>
    </Dialog>
  );
};
