import {
  ActivateDemographicSection,
  CountrySection,
  PeopleMatchSection,
} from '@/components/molecules';
import { ActivationInterest, MetaAudienceSize, SegmentInterest } from '@/types';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
  Snackbar,
  Typography,
} from '@mui/material';
import {
  IActivationCountry,
  IActivationScore,
  MetaAudienceSizeApiRequest,
} from '@/interfaces';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ActivationGender } from '@/enums';
import { ActivationSelect } from '@/components/atoms';
import { ChevronLeft } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { InterestDrawer } from '@/components/molecules';
import { PageLayout } from '@/components/layouts';
import { estimateMetaAudienceSize } from '@/api/activation';
import { useAudience } from '@/context/audience-context';
import { useAudienceActivation } from '@/hooks/useAudienceActivation';
import { useAudienceCheck } from '@/hooks/useAudienceCheck';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';

const ActivatePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const {
    loading: audienceActivationLoading,
    error: audienceActivationError,
    activation,
  } = useAudienceActivation(id);
  const { state } = useAudience();
  const {
    audienceCheck,
    error: audienceCheckError,
    loading: audienceCheckLoading,
  } = useAudienceCheck(id);

  const [selectedActivation, setSelectedActivation] = useState<string>('meta');
  const [selectedCriteria, setSelectedCriteria] = useState<number>(0);
  const [editedActivationCriteria, setEditedActivationCriteria] = useState<
    {
      topInterests: ActivationInterest[];
      editedInterests: ActivationInterest[];
    }[]
  >([]);
  const [activationCriteria, setActivationCriteria] = useState<
    {
      topInterests: ActivationInterest[];
      editedInterests: ActivationInterest[];
    }[]
  >([]);
  const [topGender, setTopGender] = useState<string | null>(null);
  const [topAge, setTopAge] = useState<string | null>(null);
  const [country, setCountry] = useState<IActivationCountry | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [interests, setInterests] = useState<ActivationInterest[]>([]);
  const [interestGroups, setInterestGroups] = useState<string[]>([]);
  const [interestTypes, setInterestTypes] = useState<string[]>([]);

  const [segmentNames, setSegmentNames] = useState<string[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<string>('');
  const [segmentInterests, setSegmentInterests] = useState<SegmentInterest[]>(
    []
  );
  const [segmentInterestsGroups, setSegmentInterestsGroups] = useState<
    string[]
  >([]);

  const [error, setError] = useState<string | null>(null);
  const [audienceSize, setAudienceSize] = useState<MetaAudienceSize | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const activationOptions = useMemo(() => {
    return activation ? Object.keys(activation.score) : [];
  }, [activation]);

  // refs to always hold the latest values
  const topGenderRef = useRef(topGender);
  const topAgeRef = useRef(topAge);
  const countryRef = useRef(country);

  // Ensure refs always hold the latest values
  useEffect(() => {
    topGenderRef.current = topGender;
    topAgeRef.current = topAge;
    countryRef.current = country;
  }, [topGender, topAge, country]);

  // trigger saveActivationCriteria when topGender, topAge or country changes
  useEffect(() => {
    if (topGender && topAge && country) {
      saveActivationCriteria();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topGender, topAge, country]);

  // check if the user has permission to view audience activations
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken: any = JSON.parse(atob(token.split('.')[1]));
        const permissions =
          decodedToken[
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/permissions`
          ] || [];
        if (!permissions.includes('social:activate'))
          setError(
            "You do not have permission to view this audience's activation"
          );
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  // check if user has access to this audience's activation
  useEffect(() => {
    if (audienceCheck) {
      if (!audienceCheck.has_access) {
        setError(
          "You do not have permission to view this audience's activation"
        );
      }
    }
  }, [audienceCheck]);

  // Update demographics and segment names when activation changes
  useEffect(() => {
    if (activation) {
      if (activation.segments) {
        setSegmentNames(activation.segments.map((segment) => segment.name));
      }

      // Compute top gender from demographics
      const gender = activation.demographics
        .filter((score) => score.type === 'Gender')
        .reduce((prev, current) =>
          prev.penetration > current.penetration ? prev : current
        );

      // Compute top age from demographics
      const age = activation.demographics
        .filter((score) => score.type === 'Age')
        .reduce((prev, current) =>
          prev.index > current.index ? prev : current
        );
      setTopGender(gender.name);
      setTopAge(age.name);
      setCountry({
        country_code: activation.country_iso,
        country_name: activation.country_name,
      });
    }
  }, [activation, id, state.audiences, audienceCheck]);

  // Update interests and criteria when the selected activation channel changes
  useEffect(() => {
    if (activation && selectedActivation) {
      // Avoid mutating the original array by copying before sorting
      const activationData = [...activation.score[selectedActivation]];
      const sortedData = activationData
        .sort((a, b) => (b.index ?? 0) - (a.index ?? 0))
        .slice(0, 15);

      // Convert activation score to activation interests fort the drawer
      const rawInterests: ActivationInterest[] = sortedData.map((interest) => ({
        name: interest.name,
        group: interest.group,
        type: interest.type,
        meta_id: interest.meta_id,
      }));

      // TODO: Remove this default when api brings in the data
      setEditedActivationCriteria([
        { topInterests: rawInterests, editedInterests: rawInterests },
      ]);
      setActivationCriteria([
        { topInterests: rawInterests, editedInterests: rawInterests },
      ]);

      const fullList = activation.score[selectedActivation] || [];
      setInterests(fullList);

      // Compute unique groups and types for filtering in the drawer
      const groups = fullList.flatMap((item) => item.group);
      const types = fullList.flatMap((item) => item.type);
      setInterestGroups([...new Set(groups)]);
      setInterestTypes([...new Set(types)]);
    }
  }, [activation, selectedActivation]);

  // Build segment interests for a selected segment without mutating data
  const getSegmentInterests = useCallback(
    (activationScore: IActivationScore, segment: string): SegmentInterest[] => {
      const interestsArray = [...(activationScore[selectedActivation] || [])];
      const sortedInterests = interestsArray.sort(
        (a, b) => (b.index ?? 0) - (a.index ?? 0)
      );
      return [{ group: segment, interests: sortedInterests }];
    },
    [selectedActivation]
  );

  // When a new segment is selected, update its interests and related groups/types
  useEffect(() => {
    if (!activation || !selectedSegment) return;

    const foundSeg = activation.segments?.find(
      (s) => s.name === selectedSegment
    );
    if (!foundSeg) return;

    // Build segmentInterests for the chosen segment
    const segInterests = getSegmentInterests(foundSeg.score, selectedSegment);
    setSegmentInterests(segInterests);

    // Build groups & types
    const groups = segInterests.flatMap((si) =>
      si.interests.map((i) => i.group)
    );
    const types = segInterests.flatMap((si) => si.interests.map((i) => i.type));
    setSegmentInterestsGroups([...new Set(groups)]);
    setInterestTypes([...new Set(types)]);
  }, [activation, selectedSegment, getSegmentInterests]);

  const allEditedInterests = useMemo(() => {
    return editedActivationCriteria.flatMap(
      (criteria) => criteria.editedInterests
    );
  }, [editedActivationCriteria]);

  const saveActivationCriteria = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const [ageMin, ageMax] = (
        topAgeRef.current?.split('-') || ['0', '0']
      ).map((str) => parseInt(str.trim(), 10));
      const genderValue =
        topGenderRef.current === 'male'
          ? ActivationGender.male
          : ActivationGender.female;

      // Filter out any empty criteria
      const newCriteria = editedActivationCriteria.filter(
        (criteria) => criteria.editedInterests.length > 0
      );

      // Update topInterests with editedInterests
      const updatedCriteria = newCriteria.map((criteria) => ({
        ...criteria,
        topInterests: criteria.editedInterests,
      }));

      // Save updated criteria to state
      setEditedActivationCriteria(updatedCriteria);
      setActivationCriteria(updatedCriteria);

      // Build interests array for the meta audience size request
      const interests = updatedCriteria.flatMap((criteria) =>
        criteria.editedInterests
          .filter((interest) => interest.meta_id !== undefined)
          .map((interest) => ({
            id: interest.meta_id as number,
            name: interest.name,
          }))
      );

      // Build meta audience size request
      const metaAudienceSizeApiRequest: MetaAudienceSizeApiRequest = {
        targeting_spec: {
          age_min: ageMin,
          age_max: ageMax,
          genders: [genderValue],
          geo_locations: {
            countries: [countryRef.current?.country_code || ''],
          },
          interests,
        },
      };
      // Call estimate audience size API
      const { data, error } = await estimateMetaAudienceSize(
        token,
        metaAudienceSizeApiRequest
      );

      if (error) {
        setError(error.message);
        setLoading(false);
      } else {
        setAudienceSize(data);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error saving activation criteria:', error);
      setError('An error occurred while saving the activation criteria.');
    }
  }, [getAccessTokenSilently, editedActivationCriteria]);

  const handleAddInterest = useCallback(
    (interest: ActivationInterest) => {
      // update edited interests for the selected criteria
      const editedInterests =
        editedActivationCriteria[selectedCriteria].editedInterests;

      // avoid adding duplicate interests
      if (!editedInterests.some((i) => i.name === interest.name)) {
        setEditedActivationCriteria((prev) => {
          const newCriteria = [...prev];
          newCriteria[selectedCriteria].editedInterests = [
            ...newCriteria[selectedCriteria].editedInterests,
            interest,
          ];
          return newCriteria;
        });
      }
    },
    [editedActivationCriteria, selectedCriteria]
  );

  const handleRemoveInterest = useCallback(
    (interestName: string) => {
      setEditedActivationCriteria((prev) => {
        const newCriteria = [...prev];
        newCriteria[selectedCriteria].editedInterests = newCriteria[
          selectedCriteria
        ].editedInterests.filter((i) => i.name !== interestName);
        return newCriteria;
      });
    },
    [selectedCriteria]
  );

  const handleSelectDeselectAll = useCallback(
    (
      group: string,
      edited: ActivationInterest[],
      segInterests?: SegmentInterest[],
      nonSegmentInterests?: ActivationInterest[]
    ) => {
      let groupInterests: ActivationInterest[] = [];

      if (segInterests?.length) {
        groupInterests = segInterests[0].interests.filter(
          (interest) => interest.group === group
        );
      } else if (nonSegmentInterests?.length) {
        groupInterests = nonSegmentInterests.filter(
          (interest) => interest.group === group
        );
      }

      const allSelected = groupInterests.every((interest) =>
        edited.some((e) => e.name === interest.name)
      );

      if (allSelected) {
        setEditedActivationCriteria((prev) => {
          const newCriteria = [...prev];
          newCriteria[selectedCriteria].editedInterests = newCriteria[
            selectedCriteria
          ].editedInterests.filter(
            (ei) => !groupInterests.some((gi) => gi.name === ei.name)
          );
          return newCriteria;
        });
      } else {
        setEditedActivationCriteria((prev) => {
          const newCriteria = [...prev];
          const interestsToAdd = groupInterests.filter(
            (interest) =>
              !edited.some((e) => e.name === interest.name) &&
              !newCriteria[selectedCriteria].editedInterests.some(
                (ei) => ei.name === interest.name
              )
          );
          newCriteria[selectedCriteria].editedInterests = [
            ...newCriteria[selectedCriteria].editedInterests,
            ...interestsToAdd,
          ];
          return newCriteria;
        });
      }
    },
    [selectedCriteria]
  );

  const handleAddCriteriaInterest = useCallback((criteriaIndex: number) => {
    setSelectedCriteria(criteriaIndex);
    setOpenDrawer(true);
  }, []);

  const handleAddCriteria = useCallback(() => {
    setEditedActivationCriteria((prev) => [
      ...prev,
      { topInterests: [], editedInterests: [] },
    ]);
  }, []);

  const handleDemographicsChange = async (
    gender: string,
    age: { min: string; max: string }
  ) => {
    const ageString = `${age.min}-${age.max}`;
    setTopGender(gender);
    setTopAge(ageString);
  };

  const handleRemoveCriteria = useCallback((index: number) => {
    setEditedActivationCriteria((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handleSave = useCallback(async () => {
    await saveActivationCriteria();
  }, [saveActivationCriteria]);

  const handleCancel = useCallback(() => {
    setEditedActivationCriteria(activationCriteria);
  }, [activationCriteria]);

  const handleOpenDrawer = useCallback((open: boolean) => {
    setOpenDrawer(open);
  }, []);

  const handleCountryChange = async (country: IActivationCountry) => {
    setCountry(country);
  };

  const handleAddActivationInterest = (interest: ActivationInterest) => {
    // check if it is a segment or not
    if (selectedSegment) {
      // update segment interests
      const newSegmentInterests = segmentInterests.map((segment) => {
        if (segment.group === selectedSegment) {
          return {
            group: segment.group,
            interests: [...segment.interests, interest],
          };
        }
        return segment;
      });
      setSegmentInterests(newSegmentInterests);
    } else {
      // update interests
      if (!interests.some((i) => i.name === interest.name)) {
        setInterests((prev) => [...prev, interest]);
      }
    }

    // add interest to the activation criteria
    if (!allEditedInterests.some((i) => i.name === interest.name)) {
      setEditedActivationCriteria((prev) => {
        const newCriteria = [...prev];
        newCriteria[selectedCriteria].editedInterests = [
          ...newCriteria[selectedCriteria].editedInterests,
          interest,
        ];
        return newCriteria;
      });
    }
  };

  return (
    <PageLayout>
      <Grid container spacing={2} px={1} justifyContent="flex-start">
        {audienceActivationLoading || audienceCheckLoading ? (
          <Grid container spacing={2}>
            <Grid xs={2}>
              <Skeleton
                variant="rounded"
                animation="wave"
                width={160}
                height={30}
              />
            </Grid>
            <Grid xs={12}>
              <Skeleton
                variant="text"
                animation="wave"
                width={140}
                height={50}
              />
            </Grid>
          </Grid>
        ) : audienceCheckError ? (
          <Grid xs={12}>
            <Alert
              severity="error"
              sx={{ background: theme.palette.background.paper }}
            >
              <AlertTitle>{audienceActivationError}</AlertTitle>
              {audienceActivationError && (
                <>
                  No Insights found for this audience. Please check that the
                  audience has been built successfully and that audience
                  insights are ready to view.
                </>
              )}
            </Alert>
          </Grid>
        ) : (
          activation && (
            <>
              <Grid xs={12} mb={2}>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<ChevronLeft />}
                  onClick={() => history.goBack()}
                >
                  Back to Insights
                </Button>
              </Grid>
              <Grid xs={1}>
                <ActivationSelect
                  selectedActivation={selectedActivation}
                  onChange={(value: React.SetStateAction<string>) =>
                    setSelectedActivation(value)
                  }
                  options={activationOptions}
                />
              </Grid>
              {selectedActivation && (
                <Grid xs={12}>
                  <Paper elevation={1}>
                    <Grid container p={1}>
                      <Grid xs={6}>
                        <Box sx={{ p: 2 }}>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            gutterBottom
                          >
                            Target Audience Details
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            This is the targeting criteria for your target
                            audience.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={6}>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h5" fontWeight="bold">
                            Audience size
                          </Typography>

                          <Box display="flex" alignItems="center" gap={1}>
                            {loading ? (
                              <Skeleton
                                variant="text"
                                height={55}
                                width={320}
                              />
                            ) : (
                              <Fragment>
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  color="primary"
                                >
                                  {audienceSize &&
                                    `${audienceSize.lower_bound.toLocaleString()} - ${audienceSize.upper_bound.toLocaleString()} people`}
                                </Typography>
                              </Fragment>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container p={1}>
                      {topGenderRef.current && topAgeRef.current && (
                        <ActivateDemographicSection
                          topGender={topGenderRef.current}
                          topAge={topAgeRef.current}
                          onSave={handleDemographicsChange}
                        />
                      )}
                      {country && (
                        <CountrySection
                          country={country}
                          onSave={handleCountryChange}
                        />
                      )}
                      {activation.score[selectedActivation].length > 0 && (
                        <PeopleMatchSection
                          activationCriteria={editedActivationCriteria}
                          onAddCriteria={handleAddCriteria}
                          onRemoveInterest={handleRemoveInterest}
                          onRemoveCriteria={handleRemoveCriteria}
                          onAddInterest={handleAddCriteriaInterest}
                          onSave={handleSave}
                          onCancel={handleCancel}
                        />
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </>
          )
        )}
      </Grid>
      {openDrawer && (
        <InterestDrawer
          openDrawer={openDrawer}
          onOpenDrawer={handleOpenDrawer}
          segmentNames={segmentNames}
          selectedSegment={selectedSegment}
          segmentInterests={segmentInterests}
          segmentInterestsGroups={segmentInterestsGroups}
          interests={interests}
          interestGroups={interestGroups}
          interestTypes={interestTypes}
          editedInterests={allEditedInterests}
          onAddActivationInterest={handleAddActivationInterest}
          onSelectedSegment={setSelectedSegment}
          onAddInterest={handleAddInterest}
          onRemoveInterest={handleRemoveInterest}
          onSelectDeselectAll={handleSelectDeselectAll}
        />
      )}

      <Snackbar open={!!error} autoHideDuration={6000}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </PageLayout>
  );
};

export default ActivatePage;
