import { AxiosRequestConfig } from 'axios';
import { fetchData } from '@/api/utils';

export const getCountries = async () => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/list/countries`,
  };

  return fetchData(config);
};

export const getMetaCountries = async () => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/list/countries/meta`,
  };

  return fetchData(config);
};
