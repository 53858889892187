import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { DefaultImage, SegmentDemogs, SegmentSizeChart } from '@/components';
import React, { useEffect, useState } from 'react';

import { Cancel } from '@mui/icons-material';
import { IAudienceSegment } from '@/interfaces';
import { SelectChangeEvent } from '@mui/material/Select';
import { interestIcon } from '@/constants/icon-map';

type SegmentCardProps = {
  segmentIndex: number;
  segment: IAudienceSegment;
  segments: IAudienceSegment[];
  selectedSegments: IAudienceSegment[];
  color: string;
  onSegmentChange: (segmentId: number, index: number) => void;
  onSegmentDelete: (segmentId: number) => void;
};

export const SegmentCard: React.FC<SegmentCardProps> = ({
  segmentIndex,
  segment,
  segments,
  selectedSegments,
  onSegmentChange,
  onSegmentDelete,
  color,
}) => {
  const [selectedSegment, setSelectedSegment] =
    useState<IAudienceSegment>(segment);

  useEffect(() => {
    setSelectedSegment(segment);
  }, [segment]);

  const segmentSize =
    selectedSegment.summary.audience_size.segment_percent * 100;

  const topAge = selectedSegment.summary.demographics
    .filter((score) => score.type === 'Age')
    .reduce((prev, current) => {
      return prev.index > current.index ? prev : current;
    });

  const topGender = selectedSegment.summary.demographics
    .filter((score) => score.type === 'Gender')
    .reduce((prev, current) => {
      return prev.penetration > current.penetration ? prev : current;
    });

  const topInterests = selectedSegment.score.interests
    .sort((a, b) => b.index - a.index)
    .slice(0, 3);

  const topBrands = selectedSegment.score.brands
    .sort((a, b) => b.index - a.index)
    .slice(0, 3);

  const topMedia = selectedSegment.score.media
    .sort((a, b) => b.index - a.index)
    .slice(0, 3);

  const handleChange = (event: SelectChangeEvent<number>) => {
    const selectedSegmentId = event.target.value as number;
    // if segment is selected, don't add it again
    const isSegmentSelected = selectedSegments.some(
      (selected) => selected.id === event.target.value
    );

    if (isSegmentSelected) return;

    const selectedSegment = segments.find(
      (segment) => segment.id === selectedSegmentId
    );

    if (selectedSegment) {
      onSegmentChange(selectedSegmentId, segmentIndex);
      setSelectedSegment(selectedSegment);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
        height: '100%',
        minWidth: '280px',
        width: '350px',
        border: `2px solid ${color}`,
      }}
    >
      <FormControl
        variant="filled"
        size="small"
        fullWidth={true}
        sx={{
          backgroundColor: color,
        }}
      >
        <InputLabel
          sx={{ color: '#fff', '&.Mui-focused': { color: '#fff' } }}
          id="view-select-label"
        >
          Choose a segment
        </InputLabel>
        <Select
          value={selectedSegment?.id}
          onChange={handleChange}
          placeholder={'Select a segment'}
          fullWidth={true}
          displayEmpty
          renderValue={(selected) =>
            selected ? selectedSegment.name : 'Select a segment'
          }
        >
          {segments?.map((seg, index) => (
            <MenuItem
              key={index}
              value={seg.id}
              disabled={selectedSegments.some(
                (selectedSegment) => selectedSegment.id === seg.id
              )}
            >
              {seg.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 2,
          px: 2,
          gap: 2,
        }}
      >
        <Typography variant="body1">Summary</Typography>
        <Box
          display={'flex'}
          flexDirection={'row'}
          height="88px"
          gap={3}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <SegmentSizeChart size={segmentSize} color={color} />
          <SegmentDemogs age={topAge} gender={topGender} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography variant="body1">Top Interests</Typography>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
          >
            {topInterests.map((interest, index) => (
              <Tooltip placement="bottom" title={interest.name} key={index}>
                <Stack key={index} spacing={1} alignItems="center" width={53}>
                  <SvgIcon
                    component={interestIcon(interest.name).ReactComponent}
                    inheritViewBox
                    sx={{ fontSize: '30px' }}
                  />
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {interest.name.split(' / ')[0]}
                  </Typography>
                </Stack>
              </Tooltip>
            ))}
          </Box>

          <Typography variant="body1">Top Brands</Typography>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
          >
            {topBrands.map((star, index) => (
              <Stack key={index} spacing={1} alignItems="center">
                <Tooltip key={star.id} placement="bottom" title={star.name}>
                  <Avatar
                    src={star.image_url}
                    alt={star.name}
                    variant="square"
                    sx={{
                      width: 53,
                      height: 53,
                      borderRadius: '6px',
                      color: '#fff',
                    }}
                  >
                    <DefaultImage size={53} name={star.name} />
                  </Avatar>
                </Tooltip>
              </Stack>
            ))}
          </Box>

          <Typography variant="body1">Top Media</Typography>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
          >
            {topMedia.map((star, index) => (
              <Stack key={index} spacing={1} alignItems="center">
                <Tooltip key={star.id} placement="bottom" title={star.name}>
                  <Avatar
                    src={star.image_url}
                    alt={star.name}
                    variant="square"
                    sx={{
                      width: 53,
                      height: 53,
                      borderRadius: '6px',
                      color: '#fff',
                    }}
                  >
                    <DefaultImage size={53} name={star.name} />
                  </Avatar>
                </Tooltip>
              </Stack>
            ))}
          </Box>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip title={'Remove Segment'}>
          <IconButton
            onClick={() => onSegmentDelete(selectedSegment.id)}
            sx={{ p: 0.5, borderRadius: '6px' }}
          >
            <Cancel color="action" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};
