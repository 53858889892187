import {
  AddCircle,
  FilterList,
  ImportExport,
  Language,
  RestartAlt,
  Search,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AudienceCard,
  BasicIndicator,
  CloneAudienceDialog,
  DeleteAudienceDialog,
  PageLayout,
  ShareAudienceDialog,
} from '@/components';
import {
  BUILD_AUDIENCE,
  BUILD_SEGMENTS,
  SHARE_AUDIENCE,
  useAudience,
} from '@/context/audience-context';
import { IAudience, ICountry, SegmentApiRequest } from '@/interfaces';
import { InsightMode, ProgressStep } from '@/enums';
import { ProgressModal, Step } from '@/components/organisms/progress-modal';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  audienceBuildProgressSteps,
  audienceRebuildProgressSteps,
} from '@/constants';
import {
  buildAudience,
  cloneAudience,
  deleteAudience,
  shareAudience,
} from '@/api/audience';
import {
  calculateSegmentInsights,
  createSegment,
  prepSegment,
} from '@/api/segment';
import { extractCountries, filterAudiences, sortAudiences } from '@/utils';

import Grid from '@mui/material/Unstable_Grid2';
import { SelectChangeEvent } from '@mui/material/Select';
import { buildInsight } from '@/api/insight';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from '@/context/snackbar-context';
import { useTheme } from '@mui/material/styles';

export const HomePage: React.FC = () => {
  const theme = useTheme();
  const { state, dispatch } = useAudience();
  const { openSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openShareAudienceDialog, setOpenShareAudienceDialog] = useState(false);
  const [openCloneAudienceDialog, setOpenCloneAudienceDialog] = useState(false);

  const [selectedAudience, setSelectedAudience] = useState<IAudience | null>(
    null
  );

  const [searchText, setSearchText] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('');
  const [filterBy, setFilterBy] = useState<string[]>([]);
  const [projectFilterBy, setProjectFilterBy] = useState<string[]>([]);
  const [projects, setProjects] = useState<string[]>([]);

  const [rows, setRows] = useState<IAudience[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const countryList = extractCountries(state.audiences);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(
    undefined
  );

  const sortOptions = [
    { name: 'default', label: 'Created Date' },
    { name: 'name', label: 'Audience Name' },
    { name: 'size', label: 'Audience Size' },
    { name: 'date', label: 'Run Date' },
  ];

  const filterOptions = useMemo(
    () => [
      { name: 'ready', label: 'Insight Ready', mode: InsightMode.READY },
      {
        name: 'stale',
        label: 'Insight Needs Refreshing',
        mode: InsightMode.STALE,
      },
      {
        name: 'missing',
        label: 'Insight Missing',
        mode: InsightMode.NEEDS_TO_RUN,
      },
    ],
    []
  );

  const [isAudienceRebuilding, setIsAudienceRebuilding] = useState(false);
  const [showAudienceRebuildProgress, setShowAudienceRebuildProgress] =
    useState(false);
  const [audienceRebuildStep, setAudienceRebuildStep] = useState<ProgressStep>(
    ProgressStep.Idle
  );
  const [steps, setSteps] = useState<Step[]>(audienceRebuildProgressSteps);

  useEffect(() => {
    const filters = filterOptions
      .filter((option) => filterBy.includes(option.name))
      .map((x) => x.mode);

    const projectFilters = projects.filter((option) =>
      projectFilterBy.includes(option)
    );

    setProjects(
      Array.from(
        new Set(
          state.audiences
            .filter((a) => a.project_name !== null)
            .map((a) => a.project_name as string)
            .sort((a, b) => a.localeCompare(b))
        )
      )
    );

    const audiences = filterAudiences(
      sortAudiences(state.audiences, sortBy),
      searchText,
      filters,
      projectFilters,
      selectedCountry
    );

    setRows(audiences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.audiences,
    searchText,
    sortBy,
    filterBy,
    selectedCountry,
    projectFilterBy,
    filterOptions,
  ]);

  const handleEdit = (audience: IAudience) => {
    if (
      audience.shared &&
      audience.shared_with &&
      audience.shared_with.length === 0
    ) {
      history.push(`/audience/${audience.id}/insight`);
    } else {
      history.push(`/audience/${audience.id}`);
    }
  };

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    if (selectedAudience && selectedAudience.id) {
      try {
        const { data } = await deleteAudience(accessToken, selectedAudience.id);
        dispatch({ type: 'SET_AUDIENCE', payload: data });
        setOpenDeleteDialog(false);
        openSnackbar('Audience deleted successfully', 'success');
      } catch (e) {
        console.log(e);
        openSnackbar('There was an error deleting your audience', 'error');
      }
    }
  };

  const handleCreateAudience = () => {
    history.push('/audience/create');
  };

  const handleOnShowInsight = (id: number | undefined) => {
    history.push(`/audience/${id}/insight`);
  };

  const handleShareAudience = (audience: IAudience) => {
    setSelectedAudience(audience);
    setOpenShareAudienceDialog(true);
  };

  const handleBuildAudience = async (audience: IAudience) => {
    const accessToken = await getAccessTokenSilently();

    if (audience.id) {
      try {
        // Build the audience
        setError(null);

        const response = await buildAudience(accessToken, audience);

        // If the audience is big enough build the insight
        if (response.data.audience_size > 0) {
          const { data } = await buildInsight(
            accessToken,
            audience.id,
            audience.country,
            response.data.request_id
          );

          // Update the context
          if (data) {
            dispatch({
              type: 'BUILD_AUDIENCE',
              payload: {
                audienceId: audience.id,
                runId: data.run_id,
                runDate: data.run_date,
                modelledCount: data.modelled_count,
                requestId: data.request_id,
                socialCount: data.social_count,
              },
            });
          }
        } else {
          setError(
            'Building your audience returned no results, please refine the query criteria'
          );
        }

        openSnackbar('Audience built successfully', 'success');
      } catch (e) {
        openSnackbar('There was an error building your audience', 'error');
        setError('There was an error building your audience');
      }
    }
  };

  const handleCloneAudience = async (audience: IAudience) => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    if (audience.id) {
      const { data, error } = await cloneAudience(accessToken, audience);

      if (error) {
        openSnackbar('There was an error cloning your audience', 'error');
        setError('There was an error cloning your audience');
        setLoading(false);
      }

      if (data) {
        dispatch({
          type: 'CLONE_AUDIENCE',
          payload: { audience: data },
        });
        setLoading(false);
        setOpenCloneAudienceDialog(false);
        openSnackbar('Audience cloned successfully', 'success');
      }
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value || '');
  };

  const handleSortSelect = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
  };

  const handleFilterSelect = (event: SelectChangeEvent<typeof filterBy>) => {
    const value = event.target.value;
    setFilterBy(typeof value === 'string' ? value.split(',') : value);
  };

  const handleProjectFilterBySelect = (
    event: SelectChangeEvent<typeof filterBy>
  ) => {
    const value = event.target.value;
    setProjectFilterBy(typeof value === 'string' ? value.split(',') : value);
  };

  const handleCountrySelect = (event: SelectChangeEvent) => {
    setSelectedCountry(
      countryList.find((x) => x.name === (event.target.value as string))
    );
  };

  const handleReset = () => {
    setSearchText('');
    setFilterBy([]);
    setProjectFilterBy([]);
    setSortBy('');
    setSelectedCountry(undefined);
  };

  const handleRebuildAudience = async (audience: IAudience) => {
    const accessToken = await getAccessTokenSilently();

    if (audience.id) {
      // check if segment has been built for this audience, if not, change the progress steps to audience build only
      if (!audience.segment_run_id) {
        setSteps(audienceBuildProgressSteps);
      }

      try {
        // Build the audience
        setShowAudienceRebuildProgress(true);
        setIsAudienceRebuilding(true);
        setAudienceRebuildStep(ProgressStep.BuildingAudience);
        const response = await buildAudience(accessToken, audience);

        // If the audience is big enough build the insight
        if (response.data.audience_size > 0) {
          // setAudienceBuildStep(ProgressStep.GettingInsights);
          const { data: audienceInsight, error } = await buildInsight(
            accessToken,
            audience.id,
            audience.country,
            response.data.request_id
          );

          if (error) {
            openSnackbar(
              'There was an error rebuilding your audience',
              'error'
            );
            setIsAudienceRebuilding(false);
            setShowAudienceRebuildProgress(false);
            setAudienceRebuildStep(ProgressStep.Idle);
            console.log(error);
            return;
          }

          // Update the context and local state
          if (audienceInsight) {
            dispatch({
              type: BUILD_AUDIENCE,
              payload: {
                audienceId: audience.id,
                requestId: audienceInsight.request_id,
                runDate: audienceInsight.run_date,
                runId: audienceInsight.run_id,
                modelledCount: audienceInsight.modelled_count,
                socialCount: audienceInsight.social_count,
              },
            });

            // rebuild the segments
            if (
              audience.id &&
              audienceInsight.request_id &&
              audienceInsight.run_id &&
              audience.segment_run_id
            ) {
              try {
                const segmentRequest: SegmentApiRequest = {
                  audience_id: audience.id,
                  country: audience.country,
                  request_id: audienceInsight.request_id,
                  run_id: audienceInsight.run_id,
                };

                // Prep segment
                setAudienceRebuildStep(ProgressStep.PreparingSegment);
                const prepSegmentData = await prepSegment(
                  accessToken,
                  segmentRequest
                );

                // Create segment
                if (prepSegmentData.data?.request_id) {
                  setAudienceRebuildStep(ProgressStep.BuildingSegment);

                  const createSegmentData = await createSegment(
                    accessToken,
                    segmentRequest
                  );

                  // Calculate segment insights
                  if (createSegmentData.data?.request_id) {
                    setAudienceRebuildStep(ProgressStep.GettingInsights);
                    const segmentInsightsData = await calculateSegmentInsights(
                      accessToken,
                      segmentRequest
                    );

                    if (segmentInsightsData.data) {
                      setAudienceRebuildStep(ProgressStep.Completed);

                      dispatch({
                        type: BUILD_SEGMENTS,
                        payload: {
                          audienceId: audience.id,
                          segmentRunId:
                            segmentInsightsData.data?.segment_run_id || 0,
                          segmentRunDate:
                            segmentInsightsData.data?.segment_run_date || '',
                          segmentCount: segmentInsightsData.data?.segment_count,
                        },
                      });
                    }
                  }
                }
              } catch (error) {
                const errorMessage =
                  error instanceof Error
                    ? error.message
                    : 'There was an error building your segments';
                openSnackbar(errorMessage, 'error');
                setShowAudienceRebuildProgress(false);
                setIsAudienceRebuilding(false);
                setAudienceRebuildStep(ProgressStep.BuildingSegmentError);
              } finally {
                setShowAudienceRebuildProgress(false);
                setIsAudienceRebuilding(false);
              }
            }

            openSnackbar('Audience rebuilt successfully', 'success');

            history.push(`/audience/${audience.id}/insight`);
          } else {
            openSnackbar(
              'Re-building your audience returned no results, please refine the query criteria and try again',
              'error'
            );
            setIsAudienceRebuilding(false);
            setShowAudienceRebuildProgress(false);
          }
        }
      } catch (e) {
        openSnackbar('There was an error rebuilding your audience', 'error');
        setAudienceRebuildStep(ProgressStep.BuildingAudienceError);
        setIsAudienceRebuilding(false);
        setShowAudienceRebuildProgress(false);
      }
    }
  };

  const handleShare = async (ids: number[]) => {
    const accessToken = await getAccessTokenSilently();
    const { data, error } = await shareAudience(
      accessToken,
      selectedAudience?.id || 0,
      ids
    );

    if (data) {
      openSnackbar(data.message, 'success');

      dispatch({
        type: SHARE_AUDIENCE,
        payload: { audienceId: selectedAudience?.id || 0, userIds: ids },
      });

      setOpenShareAudienceDialog(false);
    }

    if (error) {
      openSnackbar(error.message, 'error');
      return;
    }
  };

  return (
    <PageLayout>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        px="5%"
      >
        <Grid xs={12}>
          <Typography variant="h4">My Audiences</Typography>
        </Grid>

        <Grid xs={12}>
          <Typography variant="body1">
            Select and manage any saved audiences you have created.
          </Typography>
        </Grid>

        <Grid xs={12} container spacing={1} alignItems="center" mb={4}>
          <Grid>
            <TextField
              id="search-bar"
              variant="outlined"
              size="small"
              color="primary"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
                sx: {
                  maxWidth: '200px',
                  '& > fieldset': {
                    borderColor:
                      searchText !== '' ? theme.palette.primary.main : '',
                  },
                },
              }}
            />
          </Grid>
          <Grid>
            <Select
              multiple
              size="small"
              value={filterBy}
              onChange={handleFilterSelect}
              displayEmpty
              renderValue={(value: string[]) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <FilterList fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography
                      color={value.length > 0 ? 'textPrimary' : 'textSecondary'}
                    >
                      Insight Filter
                    </Typography>
                  </Box>
                  {value.length > 0 && (
                    <Box display="flex" flexDirection="row">
                      {filterOptions
                        .filter((option) => value.includes(option.name))
                        .map((mode) => (
                          <React.Fragment key={mode.name}>
                            <BasicIndicator mode={mode.mode} margin={0.5} />
                          </React.Fragment>
                        ))}
                    </Box>
                  )}
                </Box>
              )}
              sx={{
                minWidth: '200px',
                '& > fieldset': {
                  borderColor:
                    filterBy.length > 0 ? theme.palette.primary.main : '',
                },
              }}
            >
              {filterOptions.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  <BasicIndicator mode={option.mode} margin={1} />
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Select
              multiple
              size="small"
              value={projectFilterBy}
              onChange={handleProjectFilterBySelect}
              displayEmpty
              renderValue={(value: string[]) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <FilterList fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography
                      color={value.length > 0 ? 'textPrimary' : 'textSecondary'}
                    >
                      Campaign Filter
                    </Typography>
                  </Box>
                  {value.length === 1 && (
                    <Box display="flex" flexDirection="row">
                      <Typography color="primary" sx={{ ml: 2 }}>
                        {value[0]}
                      </Typography>
                    </Box>
                  )}
                  {value.length > 1 && (
                    <Box display="flex" flexDirection="row">
                      <Typography color="primary" sx={{ ml: 2 }}>
                        Multiple
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              sx={{
                minWidth: '200px',
                '& > fieldset': {
                  borderColor:
                    filterBy.length > 0 ? theme.palette.primary.main : '',
                },
              }}
            >
              {projects.map((project) => (
                <MenuItem key={project} value={project}>
                  {project}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Select
              size="small"
              value={sortBy}
              onChange={handleSortSelect}
              displayEmpty
              renderValue={(value: string) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <ImportExport fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography
                      color={value.length > 0 ? 'textPrimary' : 'textSecondary'}
                    >
                      Sort
                    </Typography>
                  </Box>
                  {value !== '' && (
                    <Typography color="primary" sx={{ ml: 2 }}>
                      {sortOptions.find((x) => x.name === value)?.label}
                    </Typography>
                  )}
                </Box>
              )}
              sx={{
                minWidth: '200px',
                '& > fieldset': {
                  borderColor: sortBy !== '' ? theme.palette.primary.main : '',
                },
              }}
            >
              {sortOptions.map((mode) => (
                <MenuItem key={mode.name} value={mode.name}>
                  {mode.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Select
              size="small"
              value={selectedCountry?.name || ''}
              onChange={handleCountrySelect}
              displayEmpty
              renderValue={(value: string) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Language fontSize="small" sx={{ mr: 1 }} />
                    <Typography
                      color={value.length > 0 ? 'textPrimary' : 'textSecondary'}
                    >
                      Country
                    </Typography>
                  </Box>
                  {value !== '' && (
                    <Typography color="primary" sx={{ ml: 2 }}>
                      {countryList.find((x) => x.name === value)?.name}
                    </Typography>
                  )}
                </Box>
              )}
              sx={{
                minWidth: '200px',
                '& > fieldset': {
                  borderColor: sortBy !== '' ? theme.palette.primary.main : '',
                },
              }}
            >
              {countryList.map((country) => (
                <MenuItem key={country.id} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid>
            <Tooltip title={'Reset Filters'}>
              <IconButton onClick={handleReset} sx={{ borderRadius: '6px' }}>
                <RestartAlt color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          container
          display={'flex'}
          flexDirection="column"
          spacing={3}
        >
          {state.isLoading ? (
            <Grid display={'flex'} gap={2}>
              {Array.from(new Array(5)).map((_, index) => (
                <Grid display={'flex'} key={index}>
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width={250}
                    height={200}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              {projectFilterBy.length === 0 && (
                <>
                  <Grid xs={12} container spacing={3}>
                    <Grid>
                      <Card
                        variant="outlined"
                        sx={{
                          backgroundColor: 'transparent',
                          width: '250px',
                          height: '200px',
                        }}
                      >
                        <CardActionArea
                          onClick={handleCreateAudience}
                          sx={{ height: '100%' }}
                        >
                          <CardContent
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <AddCircle color="primary" sx={{ fontSize: 50 }} />
                            <Typography variant="h6" mt={1}>
                              Create New Audience
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    {rows
                      .filter((x) => x.project_name === null)
                      .map((audience) => (
                        <Grid key={audience.id}>
                          <AudienceCard
                            audience={audience}
                            onEdit={handleEdit}
                            onBuildAudience={handleBuildAudience}
                            onRebuildAudience={handleRebuildAudience}
                            onSelectedAudience={setSelectedAudience}
                            onShowDeleteDialog={() => setOpenDeleteDialog(true)}
                            onShowInsight={handleOnShowInsight}
                            onShareAudience={handleShareAudience}
                            onCloneAudience={handleBuildAudience}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </>
              )}
              {(projectFilterBy.length > 0 ? projectFilterBy : projects).map(
                (project) => {
                  const projectAudiences = rows.filter(
                    (x) => x.project_name === project
                  );
                  return (
                    projectAudiences.length > 0 && (
                      <Fragment key={project}>
                        <Grid xs={12} mt={5} mb={1}>
                          <Grid>
                            <Divider textAlign="left">
                              <Typography variant="h5">{project}</Typography>
                            </Divider>
                          </Grid>
                        </Grid>
                        <Grid xs={12} container spacing={3}>
                          {projectAudiences.map((audience) => (
                            <Grid key={audience.id}>
                              <AudienceCard
                                audience={audience}
                                onEdit={handleEdit}
                                onBuildAudience={handleBuildAudience}
                                onRebuildAudience={handleRebuildAudience}
                                onSelectedAudience={setSelectedAudience}
                                onShowDeleteDialog={() =>
                                  setOpenDeleteDialog(true)
                                }
                                onShowInsight={handleOnShowInsight}
                                onShareAudience={handleShareAudience}
                                onCloneAudience={() => {
                                  setOpenCloneAudienceDialog(true);
                                  setSelectedAudience(audience);
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Fragment>
                    )
                  );
                }
              )}
            </>
          )}
        </Grid>

        {error && (
          <Grid xs={11} mt={2} pl={2}>
            <Alert
              severity="error"
              sx={{ background: theme.palette.background.paper }}
            >
              <AlertTitle>Build Error</AlertTitle>
              {error}
            </Alert>
          </Grid>
        )}

        {selectedAudience && (
          <CloneAudienceDialog
            open={openCloneAudienceDialog}
            isCloning={loading}
            onClose={() => setOpenCloneAudienceDialog(false)}
            onClone={handleCloneAudience}
            selectedAudience={selectedAudience}
          />
        )}

        <DeleteAudienceDialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={handleDelete}
          audienceName={selectedAudience?.name || ''}
        />
      </Grid>

      {isAudienceRebuilding && (
        <ProgressModal
          open={showAudienceRebuildProgress}
          onClose={() => setShowAudienceRebuildProgress(false)}
          progressStep={audienceRebuildStep}
          title={'Rebuilding your audience'}
          description={
            'This may take a few minutes, please do not close this window.'
          }
          steps={steps}
        />
      )}

      {selectedAudience && (
        <ShareAudienceDialog
          selectedAudience={selectedAudience}
          open={openShareAudienceDialog}
          onClose={() => setOpenShareAudienceDialog(false)}
          onShare={handleShare}
        />
      )}
    </PageLayout>
  );
};
