import {
  Box,
  Dialog,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { ActivationInterest } from '@/types';
import { ActivationInterestChip } from '@/components/atoms';
import { Search } from '@mui/icons-material';
import { searchMetaInterests } from '@/api/search';

interface AddInterestsDialogProps {
  open: boolean;
  existingInterests: ActivationInterest[];
  onClose?: () => void;
  onAddActivationInterest: (interest: ActivationInterest) => void;
  onRemoveActivationInterest: (interest: string) => void;
}

// Custom debounce hook to limit API calls
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

const AddInterestsDialog: React.FC<AddInterestsDialogProps> = ({
  open,
  existingInterests,
  onClose,
  onAddActivationInterest,
  onRemoveActivationInterest,
}) => {
  const [interests, setInterests] = useState<ActivationInterest[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedInterests, setSelectedInterests] = useState<
    ActivationInterest[]
  >([]);

  // Debounce search term to reduce API calls
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    const fetchInterests = async () => {
      // Only search if search term is long enough
      if (debouncedSearchTerm.length < 3) {
        setInterests([]);
        return;
      }

      setLoading(true);
      try {
        const { data, error } = await searchMetaInterests(debouncedSearchTerm);
        if (error) {
          console.error(error);
          setInterests([]);
        } else if (data) {
          setInterests(data);
        }
      } catch (err) {
        console.error(err);
        setInterests([]);
      } finally {
        setLoading(false);
      }
    };

    fetchInterests();
  }, [debouncedSearchTerm]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    []
  );

  const handleAddInterest = useCallback(
    (interest: ActivationInterest) => {
      setSelectedInterests((prev) => [...prev, interest]);
      onAddActivationInterest(interest);
    },
    [onAddActivationInterest]
  );

  const handleRemoveInterest = useCallback(
    (interest: string) => {
      setSelectedInterests((prev) => prev.filter((i) => i.name !== interest));
      onRemoveActivationInterest(interest);
    },
    [onRemoveActivationInterest]
  );

  console.log(selectedInterests);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h6" fontWeight="bold">
          Add Other Interests
        </Typography>
        <Typography variant="body2">
          Select the interests you would like to add to your target audience
          which are not part of the predefined interests.
        </Typography>

        <TextField
          label="Interest Search"
          variant="filled"
          fullWidth
          size="small"
          placeholder="Search for an interest"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <Box display="flex" flexDirection="column" gap={1}>
          {loading ? (
            <Grid container gap={1}>
              {Array.from(new Array(6)).map((_, index) => (
                <Grid item key={index}>
                  <Skeleton variant="rounded" width={56} height={24} />
                </Grid>
              ))}
            </Grid>
          ) : (
            interests.length > 0 && (
              <Grid container gap={1}>
                {interests.map((interest) => {
                  const isSelected = selectedInterests.some(
                    (si) => si.name === interest.name
                  );

                  const isExisting = existingInterests.some(
                    (ei) => ei.name === interest.name
                  );

                  return (
                    <Grid item key={interest.name}>
                      <ActivationInterestChip
                        interest={interest}
                        isSelected={isSelected || isExisting}
                        onAddInterest={handleAddInterest}
                        onRemoveInterest={handleRemoveInterest}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddInterestsDialog;
