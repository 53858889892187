import { Box, Chip, Grid, IconButton, Paper, Tooltip } from '@mui/material';

import { ActivationInterest } from '@/types';
import { AddCircle } from '@mui/icons-material';
import React from 'react';

interface ActivationCriteriaBoxProps {
  interests: ActivationInterest[];
  onAddInterest: () => void;
  onRemoveInterest: (name: string) => void;
}
const ActivationCriteriaBox: React.FC<ActivationCriteriaBoxProps> = ({
  interests,
  onAddInterest,
  onRemoveInterest,
}) => {
  return (
    <Paper elevation={4} variant="elevation" sx={{ p: 1.5 }}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Grid container display="flex" alignItems="center" gap={1}>
          {interests.map((item) => (
            <Grid key={item.name}>
              <Chip
                size="small"
                label={item.name}
                onDelete={() => onRemoveInterest(item.name)}
              />
            </Grid>
          ))}
          <Tooltip title="Add Interest" placement="bottom">
            <IconButton size="small" onClick={onAddInterest}>
              <AddCircle color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ActivationCriteriaBox;
