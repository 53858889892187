import { memo, useCallback } from 'react';

import { ActivationInterest } from '@/types';
import { Chip } from '@mui/material';
import { interestTypeColors } from '@/constants';

interface InterestChipProps {
  interest: ActivationInterest;
  isSelected: boolean;
  onAddInterest: (interest: ActivationInterest) => void;
  onRemoveInterest: (interest: string) => void;
}

const ActivationInterestChip: React.FC<InterestChipProps> = memo(
  ({ interest, isSelected, onAddInterest, onRemoveInterest }) => {
    const handleClick = useCallback(() => {
      if (isSelected) {
        onRemoveInterest(interest.name);
      } else {
        onAddInterest(interest);
      }
    }, [isSelected, interest, onAddInterest, onRemoveInterest]);

    const chipColor =
      interestTypeColors[interest.type as keyof typeof interestTypeColors];

    return (
      <Chip
        size="small"
        label={interest.name}
        color={isSelected ? 'primary' : 'default'}
        onClick={handleClick}
        disabled={isSelected}
        sx={{
          bgcolor: isSelected ? chipColor : 'default',
          borderColor: chipColor,
        }}
      />
    );
  }
);

export default ActivationInterestChip;
