import {
  MetaAudienceSizeApiRequest,
  MetaAudienceSizeApiResponse,
} from '@/interfaces';

import { AxiosRequestConfig } from 'axios';
import { fetchData } from '../utils';

export const estimateMetaAudienceSize = async (
  accessToken: string,
  data: MetaAudienceSizeApiRequest
): Promise<MetaAudienceSizeApiResponse> => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/activation/size`,
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  };

  return fetchData(config);
};
