import { InputAdornment, TextField } from '@mui/material';

import React from 'react';
import { Search } from '@mui/icons-material';

interface InterestSearchProps {
  onInterestSearchChange: (searchText: string) => void;
}

export const InterestSearch: React.FC<InterestSearchProps> = ({
  onInterestSearchChange,
}) => {
  return (
    <TextField
      label="Interest Search"
      variant="filled"
      fullWidth
      placeholder="Search for an interest"
      onChange={(event) =>
        event.target.value
          ? onInterestSearchChange(event.target.value)
          : onInterestSearchChange('')
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};
