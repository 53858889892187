import { AxiosRequestConfig } from "axios";
import { fetchData } from "@/api/utils";

export const getInterests = async () => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/list/interests`,
  };

  return fetchData(config);
};

export const getInterestsByIndustry = async (industry: number) => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/list/interests/${industry}`,
  };

  return fetchData(config);
};

