import { AddCircle, CancelRounded, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useState } from 'react';

import { ActivationCriteria } from '@/types';
import { ActivationCriteriaBox } from '@/components/atoms';
import Grid from '@mui/material/Unstable_Grid2';

interface PeopleMatchSectionProps {
  activationCriteria: ActivationCriteria[];
  onAddCriteria: () => void;
  onRemoveCriteria: (index: number) => void;
  onAddInterest: (selectedCriteriaIndex: number) => void;
  onRemoveInterest: (name: string, selectedCriteria: number) => void;
  onSave: () => void;
  onCancel: () => void;
}

const PeopleMatchSection: React.FC<PeopleMatchSectionProps> = ({
  activationCriteria,
  onAddCriteria,
  onAddInterest,
  onRemoveInterest,
  onRemoveCriteria,
  onSave,
  onCancel,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleSave = () => {
    onSave();
    setEditMode(false);
  };

  const handleCancel = () => {
    onCancel();
    setEditMode(false);
  };

  return (
    <Grid
      xs={12}
      container
      display="flex"
      flexDirection="row"
      columnSpacing={4}
      rowSpacing={1}
      p={2}
    >
      <Grid xs={2}>
        <Stack spacing={1}>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="body1" fontWeight="bold">
              Interests
            </Typography>
            {!editMode && (
              <Button
                variant="text"
                size="small"
                startIcon={<Edit />}
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            )}
          </Box>
          {editMode && (
            <Box display="flex" gap={1}>
              <Button variant="contained" size="small" onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" size="small" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          )}
        </Stack>
      </Grid>

      <Grid xs={6} container display="flex" flexDirection="column" gap={1}>
        {activationCriteria.map((criteria, index) => (
          <React.Fragment key={index}>
            <Grid>
              {index === 0 ? (
                <Typography fontWeight="bold">People who match</Typography>
              ) : (
                <Typography fontWeight="bold">and must also match</Typography>
              )}
            </Grid>
            {editMode ? (
              <Grid xs={12} sx={{ position: 'relative' }}>
                <Tooltip title="Remove Group" placement="top">
                  <IconButton
                    size="small"
                    sx={{ position: 'absolute', top: -12, right: -4 }}
                    onClick={() => onRemoveCriteria(index)}
                  >
                    <CancelRounded />
                  </IconButton>
                </Tooltip>
                <ActivationCriteriaBox
                  interests={criteria.editedInterests}
                  onAddInterest={() => onAddInterest(index)}
                  onRemoveInterest={(name) => onRemoveInterest(name, index)}
                />
              </Grid>
            ) : (
              <Grid xs={12}>
                <Box border={1} borderColor="gray" borderRadius={1} p={1}>
                  <Typography variant="body1">
                    {criteria.topInterests.map((item) => item.name).join(', ')}
                  </Typography>
                </Box>
              </Grid>
            )}
          </React.Fragment>
        ))}
        {editMode && (
          <Grid xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border={1}
              borderColor="gray"
              borderRadius={1}
              p={1}
              mt={2}
            >
              <Tooltip title="Add Group" placement="bottom">
                <IconButton size="small" onClick={onAddCriteria}>
                  <AddCircle color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PeopleMatchSection;
