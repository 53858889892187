export const CREATE_AUDIENCE = 'CREATE_AUDIENCE';
export const SET_AUDIENCE = 'SET_AUDIENCE';
export const SHARE_AUDIENCE = 'SHARE_AUDIENCE';
export const UPDATE_AUDIENCE = 'UPDATE_AUDIENCE';
export const DELETE_AUDIENCE = 'DELETE_AUDIENCE';
export const UPDATE_AUDIENCE_NAME = 'UPDATE_AUDIENCE_NAME';
export const UPDATE_AUDIENCE_OPERATOR = 'UPDATE_AUDIENCE_OPERATOR';
export const ADD_QUERY_GROUP = 'ADD_QUERY_GROUP';
export const DELETE_QUERY_GROUP = 'DELETE_QUERY_GROUP';
export const UPDATE_QUERY_GROUP = 'UPDATE_QUERY_GROUP';
export const BUILD_AUDIENCE = 'BUILD_AUDIENCE';
export const BUILD_SEGMENTS = 'BUILD_SEGMENTS';
export const CLONE_AUDIENCE = 'CLONE_AUDIENCE';
