import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Edit } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { IActivationCountry } from '@/interfaces';
import { titleCase } from '@/helpers';
import { useMetaCountryList } from '@/hooks/useMetaCountryList';

interface CountrySectionProps {
  country: IActivationCountry;
  onSave: (country: IActivationCountry) => void;
}

const CountrySection: React.FC<CountrySectionProps> = ({ country, onSave }) => {
  const { metaCountryList, error, loading } = useMetaCountryList();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] =
    useState<IActivationCountry>(country);

  useEffect(() => {
    setSelectedCountry(country);
  }, [country]);

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    setSelectedCountry(
      metaCountryList.find((item) => item.country_code === event.target.value)!
    );
  };

  const handleSave = () => {
    setEditMode(false);
    onSave(selectedCountry);
  };

  const handleCancel = () => {
    setSelectedCountry(country);
    setEditMode(false);
  };

  return (
    <Grid
      xs={12}
      container
      display="flex"
      flexDirection="row"
      columnSpacing={4}
      rowSpacing={1}
      p={2}
    >
      <Grid xs={2}>
        <Stack spacing={1}>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="body1" fontWeight="bold">
              Country
            </Typography>
            {!editMode && (
              <Button
                variant="text"
                size="small"
                startIcon={<Edit />}
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            )}
          </Box>
          {editMode && (
            <Box display="flex" gap={1}>
              <Button variant="contained" size="small" onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" size="small" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          )}
        </Stack>
      </Grid>

      {editMode ? (
        <Grid>
          <Stack spacing={1}>
            <Typography variant="body1" fontWeight="bold">
              Country
            </Typography>
            {loading ? (
              <CircularProgress size={24} />
            ) : error ? (
              <Typography color="error">Failed to load countries</Typography>
            ) : (
              <FormControl variant="filled" size="small" sx={{ minWidth: 180 }}>
                <InputLabel>Country</InputLabel>
                <Select
                  name="country"
                  value={selectedCountry.country_code}
                  onChange={handleCountryChange}
                  MenuProps={{ sx: { maxHeight: 300 } }}
                >
                  {metaCountryList.map((item, index) => (
                    <MenuItem key={index} value={item.country_code}>
                      {titleCase(item.country_name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Grid>
      ) : (
        <Grid>
          <Stack spacing={1}>
            <Typography variant="body1" fontWeight="bold">
              Country
            </Typography>
            <Typography variant="body1">{country.country_name}</Typography>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default CountrySection;
