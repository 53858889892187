import { AddCircle, ChevronLeft, Error } from '@mui/icons-material';
import {
  AgeCheckbox,
  HomePageSkeleton,
  NewAudienceOnboardingDialog,
  PageLayout,
  QueryGroupStepForm,
  UnsavedChangesDialog,
} from '@/components';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AudienceContext,
  BUILD_AUDIENCE,
  BUILD_SEGMENTS,
} from '@/context/audience-context';
import { IAudience, IQueryGroup, SegmentApiRequest } from '@/interfaces';
import { ProgressModal, Step } from '@/components/organisms/progress-modal';
import React, { useContext, useEffect, useState } from 'react';
import {
  SOCIAL_COUNT,
  ageOptions,
  audienceBuildProgressSteps,
  audienceRebuildProgressSteps,
  defaultAudience,
  genderOptions,
  operators,
  segmentBuildProgressSteps,
} from '@/constants';
import { buildAudience, saveAudience } from '@/api/audience';
import {
  calculateSegmentInsights,
  createSegment,
  prepSegment,
} from '@/api/segment';

import Grid from '@mui/material/Unstable_Grid2';
import { ReactComponent as LandingIcon } from '@/assets/icons/streamline/search-comment-history.svg';
import { LoadingButton } from '@mui/lab';
import { ProgressStep } from '@/enums';
import { SelectChangeEvent } from '@mui/material/Select';
import { buildInsight } from '@/api/insight';
import { getAgeSummary } from '@/helpers';
import { useAudienceCheck } from '@/hooks/useAudienceCheck';
import { useAuth0 } from '@auth0/auth0-react';
import { useCountryList } from '@/hooks/useCountryList';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSnackbar } from '@/context/snackbar-context';

export const AudiencePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { state, dispatch } = useContext(AudienceContext);
  const { openSnackbar, snackbar } = useSnackbar();
  const { audiences, isLoading } = state;
  const [audience, setAudience] = useState<IAudience>(defaultAudience);
  const { countryList } = useCountryList();
  const {
    audienceCheck,
    error: audienceCheckError,
    loading: audienceCheckLoading,
  } = useAudienceCheck(id);
  const [currentQueryGroupIndex, setCurrentQueryGroupIndex] = useState<
    number | null
  >(null);
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openNewAudienceOnboardingModal, setOpenNewAudienceOnboardingModal] =
    useState(false);

  const [queryGroupHasChanges, setQueryGroupHasChanges] = useState(false);
  const [audienceHasChanges, setAudienceHasChanges] = useState(false);
  const [audienceNameExists, setAudienceNameExists] = useState<boolean>(false);
  const { id: audienceId, run_id, query_groups, age, gender } = audience;
  const hasAudienceNameChanged = audienceHasChanges && !queryGroupHasChanges;
  const noChanges = !audienceHasChanges && !queryGroupHasChanges;
  const isNewAudience = !audienceId;
  const hasSegments = audience.segment_run_id && audience.segment_run_date;
  const displayBuildSegmentsButton = audience?.request_id && audience?.run_id;
  const disableBuildSegmentsButton =
    audience?.social_count !== undefined &&
    audience?.social_count < SOCIAL_COUNT;
  const [isAudienceBuilding, setIsAudienceBuilding] = useState(false);
  const [isSegmentBuilding, setIsSegmentBuilding] = useState(false);
  const [isAudienceRebuilding, setIsAudienceRebuilding] = useState(false);
  const [showAudienceBuildProgress, setShowAudienceBuildProgress] =
    useState(false);
  const [showSegmentBuildProgress, setShowSegmentBuildProgress] =
    useState(false);
  const [showAudienceRebuildProgress, setShowAudienceRebuildProgress] =
    useState(false);
  const [audienceBuildStep, setAudienceBuildStep] = useState<ProgressStep>(
    ProgressStep.Idle
  );
  const [segmentStep, setSegmentStep] = useState<ProgressStep>(
    ProgressStep.Idle
  );
  const [audienceRebuildStep, setAudienceRebuildStep] = useState<ProgressStep>(
    ProgressStep.Idle
  );
  const [steps, setSteps] = useState<Step[]>(audienceRebuildProgressSteps);
  const audienceSharedToUser =
    audienceCheck?.has_access && audienceCheck.is_shared;
  const [error, setError] = useState<string | null>(null);

  const projects = Array.from(
    new Set(
      audiences
        .filter((a) => a.project_name !== null)
        .map((a) => a.project_name as string)
    )
  );

  const operatorLabel = (value: string) => {
    if (value === 'AND') return 'All Of';
    else return 'Any Of';
  };

  useEffect(() => {
    if (id !== 'create') {
      // check if user has permission to view the audience
      if (audienceCheck) {
        if (!audienceCheck.has_access) {
          setError('You do not have permission to view this audience');
          return;
        }
      }

      const audienceToEdit = audiences.find((value) => value.id === Number(id));
      if (audienceToEdit) setAudience(audienceToEdit);
    } else {
      setAudience(defaultAudience);
      setOpenNewAudienceOnboardingModal(true);
    }
  }, [id, audiences, audienceCheck]);

  const handleRemoveQueryGroup = (queryGroupId: number) => {
    const newQueryGroups = query_groups.filter(
      (value) => value.id !== queryGroupId
    );

    setAudience((prevState) => ({
      ...prevState,
      query_groups: newQueryGroups,
    }));

    setQueryGroupHasChanges(true);
  };

  const handleAudienceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAudience((prev) => ({
      ...prev,
      name: event.target.value,
    }));
    setAudienceHasChanges(true);
  };

  const handleProjectNameChange = (value: string | null) => {
    if (value === '') {
      value = null;
    }

    setAudience((prev) => ({
      ...prev,
      campaign_name: value,
    }));
    setAudienceHasChanges(true);
  };

  const handleAudienceCountryChange = (event: SelectChangeEvent) => {
    setAudience((prev) => ({
      ...prev,
      country: Number(event.target.value),
    }));
    setQueryGroupHasChanges(true);
  };

  const handleAgeSelect = (age: string[]) => {
    setAudience((prevState) => ({
      ...prevState,
      age,
    }));
    setQueryGroupHasChanges(true);
  };

  const handleGenderSelect = (option: string) => {
    setAudience((prevState) => ({
      ...prevState,
      gender: [option],
    }));
    setQueryGroupHasChanges(true);
  };

  const handleChangeAudienceOperator = (operator: string) => {
    setAudience((prevState) => ({
      ...prevState,
      operator,
    }));
    setQueryGroupHasChanges(true);
  };

  const handleSaveAudience = async () => {
    const nameExistsForCountry = audiences.some(
      (a) =>
        a.name === audience?.name &&
        a.country === audience?.country &&
        a.id !== audience?.id &&
        a.project_name === audience?.project_name
    );

    if (nameExistsForCountry) {
      openSnackbar(
        'Audience name already exists for this country, please choose another one',
        'error'
      );
      setAudienceNameExists(true);
      return;
    } else {
      setAudienceNameExists(false);
    }

    if (audience?.name === '') {
      openSnackbar('Audience name is required', 'error');
      return;
    }

    const accessToken = await getAccessTokenSilently();
    try {
      const { data } = await saveAudience(
        accessToken,
        audience,
        hasAudienceNameChanged
      );

      // check if audience is an existing one or new one
      if (audience.id && audience.id === data[0].id) {
        // update existing audience
        dispatch({ type: 'UPDATE_AUDIENCE', payload: data[0] });
        openSnackbar('Audience saved successfully', 'success');
      } else {
        // create new audience
        dispatch({ type: 'CREATE_AUDIENCE', payload: data[0] });
        openSnackbar('Audience created successfully', 'success');
        history.push(`/audience/${data[0].id}`);
      }
      setAudience(data[0]);
      setAudienceHasChanges(false);
      setQueryGroupHasChanges(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleBuildAudience = async () => {
    const accessToken = await getAccessTokenSilently();
    if (audience.id) {
      setSteps(audienceBuildProgressSteps);
      try {
        // Save any changes to the audience first
        // await handleSaveAudience();

        // Build the audience
        setShowAudienceBuildProgress(true);
        setIsAudienceBuilding(true);
        setAudienceBuildStep(ProgressStep.BuildingAudience);
        const response = await buildAudience(accessToken, audience);

        // If the audience is big enough build the insight
        if (response.data.audience_size > 0) {
          setAudienceBuildStep(ProgressStep.GettingInsights);
          updateStepStatus(ProgressStep.BuildingAudience, {
            isCompleted: true,
          });
          const { data: audienceInsight, error } = await buildInsight(
            accessToken,
            audience.id,
            audience.country,
            response.data.request_id
          );

          if (error) {
            openSnackbar('There was an error building your audience', 'error');
            setIsAudienceBuilding(false);
            setShowAudienceBuildProgress(false);
            setAudienceBuildStep(ProgressStep.GettingInsightsError);
            updateStepStatus(ProgressStep.GettingInsights, {
              hasError: true,
            });
            console.log(error);
            return;
          }

          // Update the context and local state
          if (audienceInsight) {
            dispatch({
              type: BUILD_AUDIENCE,
              payload: {
                audienceId: audience.id,
                requestId: audienceInsight.request_id,
                runDate: audienceInsight.run_date,
                runId: audienceInsight.run_id,
                modelledCount: audienceInsight.modelled_count,
                socialCount: audienceInsight.social_count,
              },
            });
          }
          updateStepStatus(ProgressStep.GettingInsights, {
            isCompleted: true,
          });
          openSnackbar('Audience built successfully', 'success');
          history.push(`/audience/${id}/insight`);
        } else {
          setAudienceBuildStep(ProgressStep.BuildingAudienceError);
          updateStepStatus(ProgressStep.BuildingAudience, {
            hasError: true,
          });
          openSnackbar(
            'Building your audience returned no results, please refine the query criteria and try again',
            'error'
          );
          setIsAudienceBuilding(false);
          setShowAudienceBuildProgress(false);
        }
      } catch (e) {
        openSnackbar('There was an error building your audience', 'error');
        setIsAudienceBuilding(false);
        setShowAudienceBuildProgress(false);
        setAudienceBuildStep(ProgressStep.BuildingAudienceError);
        updateStepStatus(ProgressStep.BuildingAudience, {
          hasError: true,
        });
      }
    }
  };

  const handleAddNewAudienceGroup = () => {
    setCurrentQueryGroupIndex(null);
    setOpenQueryModal(true);
  };

  const handleEditQueryGroup = (queryGroupIndex: number) => {
    setCurrentQueryGroupIndex(queryGroupIndex);
    setOpenQueryModal(true);
  };

  const handleBackToAudiences = () => {
    if (queryGroupHasChanges || audienceHasChanges) {
      setOpenConfirmationDialog(true);
    } else {
      history.push('/');
    }
  };

  const handleOnQueryGroupConfirm = (queryGroup: IQueryGroup) => {
    if (queryGroup.id) {
      // update existing query group
      const newQueryGroups = query_groups.map((value) => {
        if (value.id === queryGroup.id) return queryGroup;
        return value;
      });
      setAudience((prevState) => ({
        ...prevState,
        query_groups: newQueryGroups,
      }));
      setQueryGroupHasChanges(true);
    } else {
      // add new query group
      const newQueryGroups = [...query_groups, queryGroup];
      setAudience((prevState) => ({
        ...prevState,
        query_groups: newQueryGroups,
      }));
      setQueryGroupHasChanges(true);
    }
  };

  const handleUnsavedChangesConfirm = () => history.push('/');

  const handleCancelChanges = () => {
    setAudience(
      audiences.find((value) => value.id === Number(id)) as IAudience
    );
    setOpenConfirmationDialog(false);
    setQueryGroupHasChanges(false);
    setAudienceHasChanges(false);
  };

  const handleCreateNewAudience = (audience: IAudience) => {
    setAudience(audience);
    setOpenNewAudienceOnboardingModal(false);
    setOpenQueryModal(true);
  };

  const handleBuildSegments = async () => {
    const { id, country, request_id, run_id } = audience;
    const accessToken = await getAccessTokenSilently();

    if (!id || !request_id || !run_id) {
      openSnackbar('Invalid audience data. Cannot build segments.', 'error');
      return;
    }
    setSteps(segmentBuildProgressSteps);
    setShowSegmentBuildProgress(true);
    setIsSegmentBuilding(true);

    try {
      const segmentRequest: SegmentApiRequest = {
        audience_id: id,
        country,
        request_id: request_id,
        run_id: run_id,
      };

      // Prep segment
      setSegmentStep(ProgressStep.PreparingSegment);
      const prepSegmentData = await prepSegment(accessToken, segmentRequest);

      // Create segment
      if (prepSegmentData.data?.request_id) {
        setSegmentStep(ProgressStep.BuildingSegment);
        updateStepStatus(ProgressStep.PreparingSegment, {
          isCompleted: true,
        });

        const createSegmentData = await createSegment(
          accessToken,
          segmentRequest
        );

        // Calculate segment insights
        if (createSegmentData.data?.request_id) {
          setSegmentStep(ProgressStep.GettingInsights);
          updateStepStatus(ProgressStep.BuildingSegment, {
            isCompleted: true,
          });

          const segmentInsightsData = await calculateSegmentInsights(
            accessToken,
            segmentRequest
          );

          if (segmentInsightsData.data) {
            setSegmentStep(ProgressStep.Completed);
            updateStepStatus(ProgressStep.GettingInsights, {
              isCompleted: true,
            });

            dispatch({
              type: BUILD_SEGMENTS,
              payload: {
                audienceId: id,
                segmentRunId: segmentInsightsData.data?.segment_run_id || 0,
                segmentRunDate:
                  segmentInsightsData.data?.segment_run_date || '',
                segmentCount: segmentInsightsData.data?.segment_count,
              },
            });

            openSnackbar('Segments built successfully', 'success');
            updateStepStatus(ProgressStep.Completed, {
              isCompleted: true,
            });

            // redirect user to insight page
            history.push(`/audience/${id}/insight`);
          } else {
            openSnackbar(
              'There was an error calculating your segments insight',
              'error'
            );
            setSegmentStep(ProgressStep.GettingInsights);
            updateStepStatus(ProgressStep.GettingInsights, {
              hasError: true,
            });
          }
        } else {
          openSnackbar('There was an error building your segments', 'error');
          setSegmentStep(ProgressStep.BuildingSegment);
          updateStepStatus(ProgressStep.BuildingSegment, {
            hasError: true,
          });
        }
      } else {
        openSnackbar('There was an error preparing your segments', 'error');
        setSegmentStep(ProgressStep.PreparingSegment);
        updateStepStatus(ProgressStep.PreparingSegment, {
          hasError: true,
        });
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? (err as Error).message
          : 'There was an error building your segments';
      setSegmentStep(ProgressStep.BuildingSegmentError);
      updateStepStatus(ProgressStep.BuildingSegment, {
        hasError: true,
      });
      openSnackbar(errorMessage, 'error');
      setShowSegmentBuildProgress(false);
      setIsSegmentBuilding(false);
    }
  };

  const handleRebuildAudience = async () => {
    const accessToken = await getAccessTokenSilently();

    if (audience.id) {
      // check if segment has been built for this audience, if not, change the progress steps to audience build only
      if (!audience.segment_run_id) {
        setSteps(audienceBuildProgressSteps);
      } else {
        setSteps(audienceRebuildProgressSteps);
      }

      try {
        // Save any changes to the audience first
        await handleSaveAudience();

        // Build the audience
        setShowAudienceRebuildProgress(true);
        setIsAudienceRebuilding(true);
        setAudienceRebuildStep(ProgressStep.BuildingAudience);
        const response = await buildAudience(accessToken, audience);

        // If the audience is big enough build the insight
        if (response.data.audience_size > 0) {
          const { data: audienceInsight, error } = await buildInsight(
            accessToken,
            audience.id,
            audience.country,
            response.data.request_id
          );

          if (error) {
            openSnackbar('There was an error building your audience', 'error');

            setIsAudienceRebuilding(false);
            setShowAudienceRebuildProgress(false);
            setAudienceRebuildStep(ProgressStep.Idle);
            return;
          }

          // Update the context and local state
          if (audienceInsight) {
            dispatch({
              type: BUILD_AUDIENCE,
              payload: {
                audienceId: audience.id,
                requestId: audienceInsight.request_id,
                runDate: audienceInsight.run_date,
                runId: audienceInsight.run_id,
                modelledCount: audienceInsight.modelled_count,
                socialCount: audienceInsight.social_count,
              },
            });

            // rebuild the segments
            if (
              id &&
              audienceInsight.request_id &&
              audienceInsight.run_id &&
              audience.segment_run_id
            ) {
              try {
                const segmentRequest: SegmentApiRequest = {
                  audience_id: audience.id,
                  country: audience.country,
                  request_id: audienceInsight.request_id,
                  run_id: audienceInsight.run_id,
                };

                // Prep segment
                setAudienceRebuildStep(ProgressStep.PreparingSegment);
                updateStepStatus(ProgressStep.BuildingAudience, {
                  isCompleted: true,
                });
                const prepSegmentData = await prepSegment(
                  accessToken,
                  segmentRequest
                );

                // Create segment
                if (prepSegmentData.data?.request_id) {
                  setAudienceRebuildStep(ProgressStep.BuildingSegment);
                  updateStepStatus(ProgressStep.PreparingSegment, {
                    isCompleted: true,
                  });
                  const createSegmentData = await createSegment(
                    accessToken,
                    segmentRequest
                  );

                  // Calculate segment insights
                  if (createSegmentData.data?.request_id) {
                    setAudienceRebuildStep(ProgressStep.GettingInsights);
                    updateStepStatus(ProgressStep.BuildingSegment, {
                      isCompleted: true,
                    });
                    const segmentInsightsData = await calculateSegmentInsights(
                      accessToken,
                      segmentRequest
                    );

                    if (segmentInsightsData.data) {
                      setAudienceRebuildStep(ProgressStep.Completed);
                      updateStepStatus(ProgressStep.GettingInsights, {
                        isCompleted: true,
                      });
                      dispatch({
                        type: BUILD_SEGMENTS,
                        payload: {
                          audienceId: audience.id,
                          segmentRunId:
                            segmentInsightsData.data?.segment_run_id || 0,
                          segmentRunDate:
                            segmentInsightsData.data?.segment_run_date || '',
                          segmentCount: segmentInsightsData.data?.segment_count,
                        },
                      });
                      updateStepStatus(ProgressStep.Completed, {
                        isCompleted: true,
                      });
                    }
                  }
                }
              } catch (error) {
                const errorMessage =
                  error instanceof Error
                    ? (error as Error).message
                    : 'There was an error building your segments';

                openSnackbar(errorMessage, 'error');

                setAudienceRebuildStep(ProgressStep.BuildingSegmentError);
                updateStepStatus(ProgressStep.BuildingSegment, {
                  isCompleted: true,
                });
                setShowAudienceRebuildProgress(false);
                setIsAudienceRebuilding(false);
              } finally {
                setShowAudienceRebuildProgress(false);
                setIsAudienceRebuilding(false);
              }
            }
            openSnackbar('Audience rebuilt successfully', 'success');

            history.push(`/audience/${id}/insight`);
          } else {
            openSnackbar(
              'Re-building your audience returned no results, please refine the query criteria and try again',
              'error'
            );

            setAudienceRebuildStep(ProgressStep.BuildingAudienceError);
            updateStepStatus(ProgressStep.BuildingAudience, {
              isCompleted: true,
            });
            setIsAudienceRebuilding(false);
            setShowAudienceRebuildProgress(false);
          }
        }
      } catch (e) {
        setAudienceRebuildStep(ProgressStep.BuildingAudienceError);
        updateStepStatus(ProgressStep.BuildingAudience, {
          isCompleted: true,
        });
        openSnackbar('There was an error building your audience', 'error');
        setIsAudienceRebuilding(false);
        setShowAudienceRebuildProgress(false);
      }
    }
  };

  const updateStepStatus = (
    stepToUpdate: ProgressStep,
    status: {
      hasError?: boolean;
      isCompleted?: boolean;
    }
  ) => {
    setSteps((currentSteps) =>
      currentSteps.map((step) => {
        if (step.step === stepToUpdate) {
          return { ...step, ...status };
        }
        return step;
      })
    );
  };

  if (audienceCheckLoading) {
    return (
      <PageLayout>
        <Grid xs={12}>
          <HomePageSkeleton />
        </Grid>
      </PageLayout>
    );
  }

  if (error || audienceCheckError) {
    return (
      <PageLayout>
        <Grid xs={12}>
          <Button
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={handleBackToAudiences}
            color="primary"
          >
            Back to Audiences
          </Button>
          <Box mt={2}>
            <Alert severity="error">
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          </Box>
        </Grid>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {!openQueryModal &&
        (isLoading ? (
          <HomePageSkeleton />
        ) : (
          <Grid
            container
            spacing={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            px="5%"
          >
            <Grid xs={12}>
              <Button
                variant="outlined"
                startIcon={<ChevronLeft />}
                onClick={handleBackToAudiences}
                color="primary"
              >
                Back to Audiences
              </Button>
            </Grid>

            <Grid xs={12} container>
              <Grid xs={10} sm={6} md={4} lg={2}>
                <TextField
                  variant="filled"
                  size="small"
                  color="primary"
                  fullWidth
                  label="Audience Name"
                  placeholder="Enter audience name"
                  value={audience?.name}
                  onChange={handleAudienceNameChange}
                  disabled={audienceSharedToUser}
                  error={
                    snackbar !== null &&
                    (audience?.name === '' || audienceNameExists)
                  }
                  helperText={
                    snackbar !== null &&
                    (audience?.name === '' || audienceNameExists) &&
                    snackbar.message
                  }
                />
              </Grid>
              <Grid xs={10} sm={6} md={4} lg={2}>
                <Autocomplete
                  freeSolo
                  value={audience.project_name}
                  onChange={(_event, value) => handleProjectNameChange(value)}
                  inputValue={audience.project_name || ''}
                  onInputChange={(_event, value) =>
                    handleProjectNameChange(value)
                  }
                  disabled={audienceSharedToUser}
                  options={projects}
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : ''
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Campaign Name"
                      size="small"
                      sx={{ mb: 2 }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={10} sm={4} md={3} lg={3} xl={2}>
                <FormControl variant="filled" fullWidth size="small">
                  <InputLabel>Country</InputLabel>
                  <Select
                    disabled={audienceSharedToUser}
                    variant="filled"
                    value={audience?.country.toString()}
                    onChange={handleAudienceCountryChange}
                  >
                    {countryList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={10} sm={4} md={2} lg={2} xl={2}>
                <FormControl variant="filled" fullWidth size="small">
                  <InputLabel>Age</InputLabel>
                  <Select
                    disabled={audienceSharedToUser}
                    multiple
                    variant="filled"
                    displayEmpty
                    value={age || []}
                    renderValue={(value: string[]) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {value && value.length > 0 && (
                          <Box display="flex" flexDirection="row">
                            {getAgeSummary(age)}
                          </Box>
                        )}
                      </Box>
                    )}
                  >
                    <FormGroup sx={{ p: 1 }}>
                      <AgeCheckbox
                        labels={ageOptions}
                        values={age}
                        onValueChange={handleAgeSelect}
                      />
                    </FormGroup>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={10} sm={4} md={2} lg={2} xl={2}>
                <FormControl variant="filled" fullWidth size="small">
                  <InputLabel>Gender</InputLabel>
                  <Select
                    multiple
                    variant="filled"
                    displayEmpty
                    disabled={audienceSharedToUser}
                    value={gender || []}
                    renderValue={(value: string[]) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {value.length > 0 && (
                          <Box display="flex" flexDirection="row">
                            {gender.toString()}
                          </Box>
                        )}
                      </Box>
                    )}
                  >
                    <FormGroup sx={{ p: 1 }}>
                      {genderOptions.map((label) => (
                        <FormControlLabel
                          key={label}
                          label={label}
                          control={
                            <Checkbox
                              checked={gender?.includes(label)}
                              onChange={() => handleGenderSelect(label)}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid xs={12}>
              <Card elevation={1}>
                <CardHeader
                  title="Query Groups"
                  titleTypographyProps={{
                    variant: 'body1',
                    fontWeight: 'bold',
                    color: 'primary',
                  }}
                  action={
                    audienceSharedToUser ? null : (
                      <Tooltip title={'Create New Query Group'}>
                        <IconButton onClick={handleAddNewAudienceGroup}>
                          <AddCircle color="secondary" />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                ></CardHeader>
                <CardContent>
                  {audience?.query_groups?.length > 0 ? (
                    <>
                      <Grid xs={12}>
                        <Select
                          size={'small'}
                          variant="outlined"
                          value={audience.operator}
                          disabled={audienceSharedToUser}
                          onChange={(event) =>
                            handleChangeAudienceOperator(
                              event.target.value as string
                            )
                          }
                        >
                          {operators.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {operatorLabel(item)}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>

                      {audience?.query_groups.map((queryGroup, index) => (
                        <Grid key={index} xs={12}>
                          <Paper elevation={9} sx={{ p: 2 }}>
                            <Stack
                              direction="row"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography variant="body2" sx={{ mr: 2 }}>
                                {queryGroup.summary}
                              </Typography>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                gap={1}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  disabled={audienceSharedToUser}
                                  onClick={() => handleEditQueryGroup(index)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  disabled={audienceSharedToUser}
                                  onClick={() =>
                                    handleRemoveQueryGroup(
                                      queryGroup.id as number
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </Box>
                            </Stack>
                          </Paper>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <Grid
                      xs={12}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack justifyContent="center" alignItems="center">
                        <SvgIcon
                          component={LandingIcon}
                          inheritViewBox
                          sx={{ fontSize: 100, mb: 2 }}
                        />
                        <Typography variant="h5" sx={{ mb: 1 }}>
                          No Query Groups
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 4 }}>
                          Add a query group to get started with your audience
                          definition
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>

            <Grid xs={12}>
              {query_groups && (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="flex-start"
                  justifyContent="flex-end"
                >
                  {run_id ? (
                    <>
                      {hasAudienceNameChanged && (
                        <>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancelChanges}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSaveAudience}
                          >
                            Save Audience
                          </Button>
                        </>
                      )}

                      {queryGroupHasChanges && (
                        <>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancelChanges}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            loading={isAudienceRebuilding}
                            disabled={isAudienceRebuilding}
                            variant="contained"
                            color="secondary"
                            onClick={handleRebuildAudience}
                          >
                            Save & Rebuild
                          </LoadingButton>
                        </>
                      )}
                      {displayBuildSegmentsButton && !hasSegments && (
                        <Tooltip
                          title={
                            disableBuildSegmentsButton
                              ? 'Audience size is too small to build segments'
                              : ''
                          }
                        >
                          <div>
                            <Button
                              disabled={disableBuildSegmentsButton}
                              variant="contained"
                              color="secondary"
                              onClick={handleBuildSegments}
                            >
                              Build Segments
                            </Button>
                          </div>
                        </Tooltip>
                      )}

                      {noChanges && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            history.push(`/audience/${id}/insight`)
                          }
                        >
                          {`View Insight${hasSegments ? '/Segments' : ''}`}
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {(isNewAudience ||
                        audienceHasChanges ||
                        queryGroupHasChanges) && (
                        <>
                          <Button
                            disabled={
                              query_groups.length === 0 ||
                              (!queryGroupHasChanges &&
                                (!audienceHasChanges ||
                                  query_groups.length === 0))
                            }
                            variant="contained"
                            color="secondary"
                            onClick={handleSaveAudience}
                          >
                            Save Audience
                          </Button>
                        </>
                      )}

                      {audienceId &&
                        !hasAudienceNameChanged &&
                        !queryGroupHasChanges && (
                          <LoadingButton
                            loading={isAudienceBuilding}
                            variant="contained"
                            color="secondary"
                            onClick={handleBuildAudience}
                          >
                            Build Audience
                          </LoadingButton>
                        )}
                    </>
                  )}
                </Stack>
              )}
            </Grid>
          </Grid>
        ))}

      {openConfirmationDialog && (
        <UnsavedChangesDialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          onConfirm={handleUnsavedChangesConfirm}
        />
      )}

      {openQueryModal && (
        <QueryGroupStepForm
          currentQueryGroupIndex={currentQueryGroupIndex}
          audience={audience}
          open={openQueryModal}
          onClose={() => setOpenQueryModal(false)}
          onConfirm={handleOnQueryGroupConfirm}
        />
      )}

      {isNewAudience && (
        <NewAudienceOnboardingDialog
          open={openNewAudienceOnboardingModal}
          onClose={() => setOpenNewAudienceOnboardingModal(false)}
          onCreate={handleCreateNewAudience}
        />
      )}

      {isAudienceBuilding && (
        <ProgressModal
          open={showAudienceBuildProgress}
          onClose={() => setShowAudienceBuildProgress(false)}
          progressStep={audienceBuildStep}
          title={'Building your audience'}
          description={
            'This may take a few minutes, please do not close this window.'
          }
          steps={steps}
        />
      )}

      {isSegmentBuilding && (
        <ProgressModal
          open={showSegmentBuildProgress}
          onClose={() => setShowSegmentBuildProgress(false)}
          progressStep={segmentStep}
          title={'Building your segment'}
          description={
            'This may take a few minutes, please do not close this window.'
          }
          steps={steps}
        />
      )}

      {isAudienceRebuilding && (
        <ProgressModal
          open={showAudienceRebuildProgress}
          onClose={() => setShowAudienceRebuildProgress(false)}
          progressStep={audienceRebuildStep}
          title={'Rebuilding your audience'}
          description={
            'This may take a few minutes, please do not close this window.'
          }
          steps={steps}
        />
      )}
    </PageLayout>
  );
};
